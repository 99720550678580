import { createRef, RefObject, useContext, useEffect, useState } from 'react';
import { handleClickOutside } from 'src/helpers';
import { DropDownIcon } from 'src/icons';
import { AuthContext } from '../context.component';

export const DropDownMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const ref: RefObject<HTMLDivElement> = createRef();
  const { setCurrentUser } = useContext(AuthContext);

  const openMenu = () => {
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const outsideClickAction = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };
  const logout = () => {
    setCurrentUser(null);
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, ref, outsideClickAction),
    );

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, ref, outsideClickAction),
      );
    };
  });

  return (
    <div className="relative">
      <DropDownIcon className="fcrm-drop-down-i ml-5" onClick={openMenu} />
      {showMenu && (
        <div className="fcrm-tmenu-ddown-item" ref={ref}>
          <div className="fcrm-tmenu-ddown-item-t" onClick={logout}>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};
