import { useContext } from 'react';
import { AuthContext } from './context.component';
import { Layout } from 'src/layout';
import { AuthRoutes } from './routes/AuthRoutes';
import { UnauthorizedRoutes } from './routes/AnauthorizedRoutes';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

export const App = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Layout>
          {!currentUser ? <UnauthorizedRoutes /> : <AuthRoutes />}
        </Layout>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
