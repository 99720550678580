import { SVGProps } from 'react';

export const CompaignsIcon = ({ className, width, height }: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 24;
  const resHeight = height || 24;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox="0 0 24 24"
      stroke="#84ABF8"
      fill="none">
      <path
        d="M18.3047 18.3047L23.25 23.25M21.1875 11.3438C21.1875 16.7803 16.7803 21.1875 11.3438 21.1875C5.9072 21.1875 1.5 16.7803 1.5 11.3438C1.5 5.9072 5.9072 1.5 11.3438 1.5C16.7803 1.5 21.1875 5.9072 21.1875 11.3438Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5L13.5 9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.6567L10.2675 16.3891C9.99461 16.6621 9.6706 16.8786 9.314 17.0263C8.95739 17.174 8.57519 17.25 8.18921 17.25C7.40968 17.25 6.66208 16.9403 6.11087 16.3891C5.55967 15.8379 5.25 15.0903 5.25 14.3108C5.25 13.5313 5.55967 12.7837 6.11087 12.2325L7.84333 10.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6567 12L16.3891 10.2675C16.9403 9.71634 17.25 8.96874 17.25 8.18921C17.25 7.40968 16.9403 6.66208 16.3891 6.11087C15.8379 5.55967 15.0903 5.25 14.3108 5.25C13.5313 5.25 12.7837 5.55967 12.2325 6.11087L10.5 7.84333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
