import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import {
  BaseSyntheticEvent,
  createRef,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import Select from 'react-select';
import {
  DropDownMenuPlacementEnum,
  OptionType,
  RolesEnum,
  UserStatusEnum,
  userStatusesOptions,
} from 'src/types';
import { AddButton } from '../buttons';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  getAddjustedFormPosition,
  getCompanyOptionsList,
  getMyAvailableRolesOptionsList,
  getRoleOptionByValue,
  handleClickOutside,
} from 'src/helpers';
import {
  AddUserToCompanyMutation,
  StoreUserMutation,
  UserType,
} from './types';
import { useMutation, useQuery } from '@apollo/client';
import { AuthContext } from '../context.component';
import { config } from 'src/configs/config';

import styles from './store-user.module.css';
import { GET_COMPANIES_QUERY, GetCompaniesType } from '../company';
import { ADD_USER_TO_COMPANY_MUTATION, STORE_USER_MUTATION } from './queries';

type InputsType = {
  id?: string;
  username: string;
  email: string;
  password?: string;
  status: OptionType;
  role: OptionType;
  company: OptionType;
};

type ConfirmedChagesType = {
  role: boolean;
  status: boolean;
  company: boolean;
};

const getDefaultValues = (
  userId?: string | null,
  userData?: Partial<UserType> | null,
): Partial<InputsType> => ({
  ...(userData ? userData : {}),
  status:
    userData?.status && userData.status === UserStatusEnum.INACTIVE
      ? { value: UserStatusEnum.INACTIVE, label: 'Inactive' }
      : userData?.status && userData.status === UserStatusEnum.NOT_CONFIRMED
      ? { value: UserStatusEnum.NOT_CONFIRMED, label: 'Not confirmed' }
      : { value: UserStatusEnum.ACTIVE, label: 'Active' },
  role: getRoleOptionByValue(userData?.role as RolesEnum),
  company: userData?.company?.[0]
    ? { value: userData?.company?.[0].id, label: userData?.company?.[0].name }
    : undefined,
  ...(userId ? { id: userId } : {}),
});

const COMPANIES_LIMIT = 500;

export const StoreUser = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    edit?: boolean;
    userId?: string | null;
    defaultValues?: Partial<UserType> | null;
    previewOnly?: boolean;
    isPopupMode?: boolean;
    onUserStored?: (userData: UserType | any) => void;
    onClose?: () => void;
  }
>(
  (
    { edit, userId, defaultValues, onUserStored, isPopupMode, onClose },
    ref: ForwardedRef<HTMLDivElement> | undefined,
  ) => {
    const { currentUser } = useContext(AuthContext);
    const [showAddUser, setShowAddUser] = useState(!!edit);
    const [confirmedChanges, setConfirmedChanges] =
      useState<ConfirmedChagesType>({
        role: true,
        status: true,
        company: true,
      });
    const [dropDownMenuPlacement, setDropDownMenuPlacement] =
      useState<DropDownMenuPlacementEnum>(DropDownMenuPlacementEnum.BOTTOM);
    const formRef: RefObject<HTMLDivElement> = createRef();
    const backdrop = useRef<HTMLDivElement>(null);

    const { register, handleSubmit, reset, control, setValue } =
      useForm<InputsType>({
        resolver: yupResolver(
          yup.object().shape({
            id: yup.string().notRequired(),
            username: yup.string().required('Name of a user is required'),
            email: yup.string().required('Email is required').email(),
            password: !edit
              ? yup.string().required('Password is required')
              : yup.string().notRequired(),
            status: yup
              .object()
              .shape({
                value: yup.string().required(),
                label: yup.string().required(),
              })
              .notRequired(),
            role: yup
              .object()
              .shape({
                value: yup.string().required(),
                label: yup.string().required(),
              })
              .notRequired(),
          }),
        ),
        defaultValues: useMemo(
          () => getDefaultValues(userId, defaultValues),
          [defaultValues],
        ),
      });
    const { errors, isDirty, dirtyFields, touchedFields } = useFormState({
      control,
    });
    const [storeUser, { data, loading, error }] =
      useMutation<StoreUserMutation>(STORE_USER_MUTATION);

    const { data: companiesData, loading: loadingCompaniesData } =
      useQuery<GetCompaniesType>(GET_COMPANIES_QUERY, {
        variables: { first: COMPANIES_LIMIT },
      });

    const [
      addUserToCompany,
      {
        data: addUserToCompanyData,
        loading: addUserToCompanyLoading,
        error: addUserToCompanyError,
      },
    ] = useMutation<AddUserToCompanyMutation>(ADD_USER_TO_COMPANY_MUTATION);

    const displayForm = () => {
      if (!showAddUser) {
        setShowAddUser(true);
      }
    };
    const outsideClickAction = () => {
      if (showAddUser) {
        setShowAddUser(false);
      }
    };

    const send = async (formData: InputsType): Promise<void> => {
      if (edit && formData.id) {
        if (
          !loading &&
          (formData.password !== '' ||
            formData.username !== defaultValues?.username ||
            formData.email !== defaultValues.email ||
            formData.status.value !== defaultValues?.status ||
            formData.role.value !== defaultValues.role)
        ) {
          await storeUser({
            variables: {
              input: {
                id: formData.id,
                ...(formData.password ? { password: formData.password } : {}),
                username: formData.username,
                email: formData.email,
                status: formData.status.value,
                role: formData.role.value,
              },
            },
          });
        }

        if (
          !addUserToCompanyLoading &&
          isPopupMode &&
          formData.company?.value !== defaultValues?.company?.[0]?.id
        ) {
          await addUserToCompany({
            variables: {
              input: {
                companyId: formData.company.value,
                userId: formData.id,
                ...(edit ? { moveOnly: true } : {}),
              },
            },
          });
        }
      } else {
        if (!loading) {
          await storeUser({
            variables: {
              input: {
                ...(formData.id ? { id: formData.id } : {}),
                ...(formData.password ? { password: formData.password } : {}),
                username: formData.username,
                email: formData.email,
                status: formData.status.value,
                role: formData.role.value,
              },
            },
          });
        }
      }
    };

    const submitData = (formData: InputsType, e?: BaseSyntheticEvent) => {
      e?.preventDefault();

      send(formData);
    };
    const addjustFormPositions = () => {
      const currRef = (ref || formRef) as RefObject<HTMLDivElement>;

      if (!currRef || !currRef.current) {
        return;
      }

      const adjustedPos = getAddjustedFormPosition(currRef.current, 100);

      if (adjustedPos.top > 0) {
        currRef.current.style.top = `-${adjustedPos.top}px`;
      }
      if (adjustedPos.dropDownMenuPlacement !== dropDownMenuPlacement) {
        setDropDownMenuPlacement(adjustedPos.dropDownMenuPlacement);
      }
    };

    useEffect(() => {
      addjustFormPositions();

      if (!edit) {
        document.addEventListener('mousedown', (e) =>
          handleClickOutside(e, formRef, outsideClickAction),
        );

        return () => {
          document.removeEventListener('mousedown', (e) =>
            handleClickOutside(e, formRef, outsideClickAction),
          );
        };
      }
    });

    useEffect(() => {
      if (edit && userId) {
        if (
          !loading &&
          !error &&
          !addUserToCompanyLoading &&
          !addUserToCompanyError &&
          (data?.storeUser || addUserToCompanyData?.addUserToCompany)
        ) {
          if (isPopupMode) {
            reset();
          } else {
            reset(getDefaultValues(userId, data?.storeUser));
          }

          if (onUserStored && data?.storeUser) {
            onUserStored(data.storeUser);
          } else if (onUserStored) {
            onUserStored(undefined);
          }
        }
      } else {
        if (!loading && !error && data?.storeUser) {
          if (isPopupMode) {
            reset();
          } else {
            reset(getDefaultValues(userId, data?.storeUser));
          }

          if (!edit) {
            setShowAddUser(false);
          }

          if (onUserStored) {
            // onUserStored(data.storeUser);
            onUserStored(undefined);
          }
        }
      }
    }, [data, addUserToCompanyData]);

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (backdrop?.current === e.target && onClose) {
        onClose();
      }

      setShowAddUser(false);
    };

    return (
      <div className="relative">
        {!edit &&
          currentUser?.role &&
          RolesEnum.CHATTER !== (currentUser.role as RolesEnum) && (
            <AddButton
              btnText="Add user"
              className="mb-2.5"
              onClick={displayForm}
            />
          )}
        {showAddUser && (
          <div className={cn({ [styles.dialog]: isPopupMode })}>
            <div
              className={cn({
                [styles.content]: isPopupMode,
                'fcrm-form-dialog-plain': !isPopupMode,
              })}
              ref={ref || formRef}>
              <h3 className="fcrm-h3">
                {!edit
                  ? 'Add new user'
                  : isPopupMode
                  ? defaultValues?.username
                  : ''}
              </h3>
              {((!loading && error) ||
                (!addUserToCompanyLoading && addUserToCompanyError)) && (
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2"></div>
                  <div className="fcrm-error-i">{error?.message}</div>
                  <div className="fcrm-error-i">
                    {addUserToCompanyError?.message}
                  </div>
                </div>
              )}
              <form className="pt-5" onSubmit={handleSubmit(submitData)}>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Name:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="User name"
                      className="fcrm-form-d-item"
                      {...register('username')}
                    />
                    {errors.username && (
                      <div className="fcrm-error-i">
                        {errors.username.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Email:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Email"
                      className="fcrm-form-d-item"
                      {...register('email')}
                    />
                    {errors.email && (
                      <div className="fcrm-error-i">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Password:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      type="password"
                      placeholder="Password"
                      className="fcrm-form-d-item"
                      {...register('password')}
                    />
                    {errors.password && (
                      <div className="fcrm-error-i">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Role:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getMyAvailableRolesOptionsList(
                            currentUser?.role,
                          )}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                          onChange={(val) => {
                            if (edit) {
                              setConfirmedChanges({
                                ...confirmedChanges,
                                role: val?.value === defaultValues?.role,
                              });
                            }

                            if (val) {
                              setValue('role', val);
                            }
                          }}
                        />
                      )}
                    />
                    {!errors?.role && !confirmedChanges.role && (
                      <div className="fcrm-error-i flex flex-row">
                        Please{' '}
                        <div
                          className="hover:cursor-pointer underline mx-1.5"
                          onClick={() =>
                            setConfirmedChanges({
                              ...confirmedChanges,
                              role: true,
                            })
                          }>
                          confirm
                        </div>{' '}
                        changing of the role
                      </div>
                    )}
                    {errors?.role && (
                      <div className="fcrm-error-i">{errors.role.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Status:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={userStatusesOptions}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                          onChange={(val) => {
                            if (edit) {
                              setConfirmedChanges({
                                ...confirmedChanges,
                                status: val?.value === defaultValues?.status,
                              });
                            }

                            if (val) {
                              setValue('status', val);
                            }
                          }}
                        />
                      )}
                    />
                    {!errors?.status && !confirmedChanges.status && (
                      <div className="fcrm-error-i flex flex-row">
                        Please{' '}
                        <div
                          className="hover:cursor-pointer underline mx-1.5"
                          onClick={() =>
                            setConfirmedChanges({
                              ...confirmedChanges,
                              status: true,
                            })
                          }>
                          confirm
                        </div>{' '}
                        changing of the status
                      </div>
                    )}
                    {errors.status && (
                      <div className="fcrm-error-i">
                        {errors.status.message}
                      </div>
                    )}
                  </div>
                </div>
                {/* {currentUser &&
                currentUser.role === RolesEnum.SUPER_ADMIN &&
                isPopupMode ? (
                  <div className="flex flex-row items-start pb-4">
                    <div className="w-1/4 pt-2">Autologin link:</div>
                    <div className="w-2/3 fcrm-form-d-item-link">
                      <a
                        href={
                          defaultValues?.autologinKey
                            ? `fanscrm://open/${defaultValues?.autologinKey}`
                            : 'javascript:void(0)'
                        }
                        target="_blank"
                        rel="noopener noreferrer">
                        {defaultValues?.autologinKey
                          ? `fanscrm://open/${defaultValues?.autologinKey}`
                          : 'none'}
                      </a>
                    </div>
                  </div>
                ) : null} */}

                {edit && userId && isPopupMode ? (
                  <div className="flex flex-row items-start">
                    <div className="w-1/4 pt-2">Agency:</div>
                    <div className="w-2/3 pb-4">
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              !loadingCompaniesData &&
                              companiesData?.AgencyGroup?.getCompanies
                                ?.edges &&
                              companiesData?.AgencyGroup?.getCompanies?.edges
                                ?.length > 0
                                ? getCompanyOptionsList(
                                    companiesData.AgencyGroup.getCompanies.edges.map(
                                      (edge) => ({
                                        ...edge.node,
                                      }),
                                    ),
                                  )
                                : ([
                                    { value: '', label: 'LOADING...' },
                                  ] as OptionType[])
                            }
                            menuPlacement={dropDownMenuPlacement}
                            maxMenuHeight={config.maxDropDownMenuHeight}
                            onChange={(val) => {
                              if (edit) {
                                setConfirmedChanges({
                                  ...confirmedChanges,
                                  company:
                                    val?.value ===
                                    defaultValues?.company?.[0]?.id,
                                });
                              }

                              if (val) {
                                setValue('company', val);
                              }
                            }}
                          />
                        )}
                      />
                      {!errors?.company && !confirmedChanges.company && (
                        <div className="fcrm-error-i flex flex-row">
                          Please{' '}
                          <div
                            className="hover:cursor-pointer underline mx-1.5"
                            onClick={() =>
                              setConfirmedChanges({
                                ...confirmedChanges,
                                company: true,
                              })
                            }>
                            confirm
                          </div>{' '}
                          changing of the agency
                        </div>
                      )}
                      {errors?.company && (
                        <div className="fcrm-error-i">
                          {errors.company?.message}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {(!edit ||
                  (isDirty && !!Object.values(dirtyFields).length) ||
                  !!Object.values(touchedFields).length) &&
                  confirmedChanges.status &&
                  confirmedChanges.role &&
                  confirmedChanges.company && (
                    <button
                      type="submit"
                      className="fcrm-submit w-28 bg-blue-500 py-2.5 my-1.5 rounded-full m-auto hover:bg-blue-600 active:bg-blue-700">
                      Save
                    </button>
                  )}
              </form>
            </div>
            {isPopupMode && (
              <div
                className={styles.backdrop}
                ref={backdrop}
                onClick={handleClose}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);
