import { CompanyType } from 'src/components';
import { OptionType } from 'src/types';

export const getCompanyOptionsList = (
  srcCompanies: Partial<CompanyType>[],
): OptionType[] => {
  return srcCompanies.map((item) => ({
    value: item?.id ? item.id : '',
    label: item?.name ? item.name : '',
  }));
};
