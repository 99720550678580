import { SVGProps } from 'react';

export const ChatModelsIcon = ({
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 16;
  const resHeight = height || 18;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 2.125C0.375 1.02043 1.27043 0.125 2.375 0.125H13.625C14.7296 0.125 15.625 1.02043 15.625 2.125V17.125C15.625 17.5392 15.2892 17.875 14.875 17.875C14.4608 17.875 14.125 17.5392 14.125 17.125V14.125H1.875V17.125C1.875 17.5392 1.53921 17.875 1.125 17.875C0.710786 17.875 0.375 17.5392 0.375 17.125V2.125ZM14.125 12.625V7.875H1.875V12.625H14.125ZM14.125 2.125V6.375H1.875V2.125C1.875 1.84886 2.09886 1.625 2.375 1.625H13.625C13.9011 1.625 14.125 1.84886 14.125 2.125ZM7 9.41687C7 8.86459 7.44772 8.41687 8 8.41687H8.00833C8.56062 8.41687 9.00833 8.86459 9.00833 9.41687C9.00833 9.96916 8.56062 10.4169 8.00833 10.4169H8C7.44772 10.4169 7 9.96916 7 9.41687ZM8 2.16687C7.44772 2.16687 7 2.61459 7 3.16687C7 3.71915 7.44772 4.16687 8 4.16687H8.00833C8.56062 4.16687 9.00833 3.71915 9.00833 3.16687C9.00833 2.61459 8.56062 2.16687 8.00833 2.16687H8Z"
      />
    </svg>
  );
};
