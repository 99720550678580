import { SortOrder } from 'antd/es/table/interface';

const checkSortOrder = (
  key: string,
  { field, order }: { field?: string | null; order?: string | null },
) => {
  return field === key ? (order as SortOrder) : undefined;
};

export { checkSortOrder };
