import { SmallLogoIcon } from 'src/icons';
import { UserMenu } from './user-menu.component';

export const TopMenu = () => {
  return (
    <div className="w-screen bg-gray-700 m-auto flex flex-row items-center px-[20px] h-[52px]">
      <div className="flex flex-row items-center flex-1">
        <SmallLogoIcon />
      </div>
      <div className="flex flex-row-reverse items-center flex-1">
        <UserMenu />
      </div>
    </div>
  );
};
