import {
  ApolloQueryResult,
  DocumentNode,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { useState, useMemo } from 'react';
import { PaginationType } from 'src/types';
import useUpdateEffect from './useUpdateEffect.hook';
import isEqual from 'fast-deep-equal';

interface UseQueryHookProps<TData, TList> {
  query: DocumentNode;
  variables?: Record<string, any>;
  dumpFunction: (data: TData) => {
    list: TList[];
    pageInfo: PaginationType | null;
  };
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useQueryHook = <TResponse extends Record<string, any>, TList>({
  query,
  variables = {},
  dumpFunction,
  fetchPolicy,
}: UseQueryHookProps<TResponse, TList>) => {
  const [data, setData] = useState<TList[]>([]);
  const [pageData, setPageData] = useState<PaginationType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const memoizedVariables = useMemo(() => {
    return variables;
  }, [variables]);

  useUpdateEffect(() => {
    if (!isEqual(memoizedVariables, variables)) {
      setIsLoading(true);
    }
  }, [memoizedVariables, variables]);

  const { loading, error, refetch } = useQuery<TResponse>(query, {
    fetchPolicy: fetchPolicy ?? 'no-cache',
    variables: memoizedVariables,
    onCompleted: (response) => {
      const { list, pageInfo } = dumpFunction(response);

      setData(list);
      setPageData(pageInfo);
      setIsLoading(false);
    },
    onError: () => setIsLoading(false),
  });

  const refetchList = async () => {
    const response: ApolloQueryResult<TResponse> = await refetch();
    if (response.data) {
      const { list, pageInfo } = dumpFunction(response.data);
      setData(list);
      setPageData(pageInfo);
    }
  };

  return {
    loading: loading || isLoading,
    data,
    pageData,
    error,
    refetch: refetchList,
    updateData: setData,
  };
};

export default useQueryHook;
