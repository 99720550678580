import { ReactNode } from 'react';
import cx from 'classnames';

import styles from './style.module.css';
import { LoaderUi } from 'src/ui';

interface ButtonProps {
  text?: string | ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'green' | 'grey';
  buttonType?: 'submit' | 'button';
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  width?: string;
  border?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  variant = 'primary',
  className = '',
  disabled,
  isLoading,
  icon,
  width,
  border,
  buttonType = 'submit',
}) => {
  const handleClick = () => {
    if (isLoading || disabled) return;

    if (onClick) onClick();
  };

  return (
    <button
      type={buttonType}
      style={{ width }}
      className={cx(styles.button, {
        [className]: className,
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.green]: variant === 'green',
        [styles.grey]: variant === 'grey',
        [styles.border]: border,
      })}
      onClick={handleClick}
      disabled={disabled}>
      {icon && icon}
      {!isLoading ? text : <LoaderUi />}
    </button>
  );
};

export default Button;
