import { useEffect, useState, FC } from 'react';
import { useMutation } from '@apollo/client';
import { SettingsType, UpdateAppUrlSettingsFetchType } from '../types';
import { UPDATE_APP_CONFIG_MUTATION } from '../queries';
import styles from './EditUrlComponent.module.css';
import { ConfirmationModal } from 'src/components/base/confirmationModal';
import { JsonData, JsonEditor } from 'json-edit-react';
import { LoaderUi } from 'src/ui';

interface IProps {
  onUrlStored?: () => Promise<void>;
  config: SettingsType | null;
  onClose: () => void;
}

const GENERAL_SCHEMA_ERROR =
  ' The array must contain objects that store the key and value.';
const URL_OBJECT_SCHEMA_ERROR = 'Schema error: key and value is required.';

export const EditUrlComponent: FC<IProps> = ({
  onUrlStored,
  config,
  onClose,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [urlSchemaError, setUrlSchemaError] = useState('');
  const [configUrlsData, setConfigUrlsData] = useState<JsonData>(
    config?.value && Array.isArray(config.value) ? config.value : [],
  );

  const [storeAppUrl, { data, loading, error }] =
    useMutation<UpdateAppUrlSettingsFetchType>(UPDATE_APP_CONFIG_MUTATION);

  const isValidUrlArray = (editedData: any): boolean => {
    return (
      Array.isArray(editedData) &&
      editedData.length > 0 &&
      editedData.every(
        (item) =>
          typeof item === 'object' &&
          typeof item?.key === 'string' &&
          typeof item?.value === 'string',
      )
    );
  };

  const send = async (): Promise<void> => {
    if (!loading && config) {
      await storeAppUrl({
        variables: {
          settings: {
            id: Number(config.id),
            key: config.key,
            value: JSON.stringify(configUrlsData),
          },
        },
      });
    }
  };

  const handleSaveClick = () => {
    if (isValidUrlArray(configUrlsData)) {
      setUrlSchemaError('');
      setShowConfirmModal(true);
    } else {
      setUrlSchemaError(GENERAL_SCHEMA_ERROR);
    }
  };

  const handleConfirmSave = async () => {
    await send();
    setShowConfirmModal(false);
  };

  const handleCancelSave = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (!loading && data?.updateAppSettings && !error) {
      if (onUrlStored) {
        onUrlStored();
      }
      onClose();
    }
  }, [data]);

  useEffect(() => {
    setShowConfirmModal(false);
  }, [error]);

  return (
    <div className="relative">
      <div className={styles.dialog}>
        <div className={styles.content}>
          <h3 className="fcrm-h3 mb-5">URL configuration</h3>
          {!showConfirmModal && (
            <>
              {!loading && error && (
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2"></div>
                  <div className="fcrm-error-i">{error.message}</div>
                </div>
              )}

              <JsonEditor
                data={configUrlsData}
                setData={setConfigUrlsData}
                rootName={config?.key}
                className={styles.editor}
                onDelete={({ newData }) => {
                  const isValid = isValidUrlArray(newData);
                  if (!isValid) {
                    return URL_OBJECT_SCHEMA_ERROR;
                  }
                }}
              />

              {urlSchemaError && (
                <p className="fcrm-error-i !text-sm m-auto my-[10px]">
                  {urlSchemaError}
                </p>
              )}

              <button
                type="button"
                className="fcrm-submit w-28 bg-blue-500 py-2.5 my-1.5 rounded-full m-auto hover:bg-blue-600 active:bg-blue-700"
                onClick={handleSaveClick}>
                Save
              </button>
            </>
          )}

          {showConfirmModal && (
            <ConfirmationModal
              confirmationText="Your changes will be applied to the  Fans-CRM application. Are you sure these changes are necessary?"
              onCancelAction={handleCancelSave}
              onConfirmAction={handleConfirmSave}
            />
          )}

          {loading && (
            <div className={styles.loader}>
              <LoaderUi />
            </div>
          )}
        </div>
        <div className={styles.backdrop} onClick={onClose} />
      </div>
    </div>
  );
};
