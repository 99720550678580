import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  gql,
  FetchResult,
} from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react/context';
import { setContext } from '@apollo/client/link/context';
import { config } from 'src/configs/config';
import {
  DetailedHTMLProps,
  HTMLAttributes,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { AuthContext } from './context.component';
import { onError } from '@apollo/client/link/error';
import { getCookie } from 'src/helpers/get-cookie.helper';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN_FANS_CRM,
} from 'src/constants/token-name';
import { Observable } from 'rxjs';

const graphqlLink = createHttpLink({
  uri: config.graphQlUrl,
});

const cache = new InMemoryCache();

const REFRESH_TOKEN_MUTATION = gql`
  mutation getRefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken)
  }
`;

const ERROR_REFRESH_ACCESS_TOKEN = 'Failed to refresh access token';
const ERROR_UNAUTHORIZED = 'Unauthorized';
const ERROR_FORBIDDEN = 'Forbidden!';

export const ApolloProviderContext = ({
  children,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const apolloClient = useMemo(() => {
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }): any => {
        if (graphQLErrors) {
          for (const errorApollo of graphQLErrors) {
            if (errorApollo.message === ERROR_REFRESH_ACCESS_TOKEN) {
              setCurrentUser(null);
            }

            if (
              errorApollo.message === ERROR_UNAUTHORIZED ||
              errorApollo.message === ERROR_FORBIDDEN
            ) {
              const observable = new Observable<
                FetchResult<Record<string, any>>
              >((observer) => {
                (async () => {
                  try {
                    const cookieRes = getCookie(REFRESH_TOKEN_FANS_CRM);

                    if (!cookieRes) {
                      console.error(
                        '[createApolloClient::onError]: Empty refresh token.',
                      );
                      setCurrentUser(null);
                    }

                    try {
                      const { data } = await apolloClient.mutate({
                        mutation: REFRESH_TOKEN_MUTATION,
                        variables: { refreshToken: cookieRes },
                      });

                      if (data && data.refreshAccessToken) {
                        const newToken = data.refreshAccessToken;

                        localStorage.setItem(ACCESS_TOKEN, newToken);

                        const subscriber = {
                          next: observer.next.bind(observer),
                          error: observer.error.bind(observer),
                          complete: observer.complete.bind(observer),
                        };

                        forward(operation).subscribe(subscriber);
                      }
                    } catch (error) {
                      console.error(
                        '[createApolloClient::onError] - Error refreshing tokens:',
                        error,
                      );
                    }
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              });

              return observable;
            }
          }
        }

        if (networkError) {
          console.error(
            `[createApolloClient::onError] - Network error: ${networkError}`,
          );
        }
      },
    );

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem(ACCESS_TOKEN);

      return {
        headers: {
          ...headers,
          'fcrm-sign':
            'c2xrZDIzNHNiZGZrc3piYzIzYXNkYmthYjIzdXNkdmJrc2RoMzk4YWhzdg==',
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return new ApolloClient({
      cache,
      link: ApolloLink.from([errorLink, authLink, graphqlLink]),
    });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      apolloClient.clearStore().catch((err) => console.error(err));
    }
  }, [currentUser]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
