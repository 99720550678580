import { SVGProps } from 'react';

export const MyProfileIcon = ({
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 18;
  const resHeight = height || 17;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75012 6.5C4.75012 4.15279 6.65292 2.25 9.00012 2.25C11.3473 2.25 13.2501 4.15279 13.2501 6.5C13.2501 8.82585 11.3818 10.7153 9.06408 10.7495C9.04277 10.7494 9.02145 10.7493 9.00012 10.7493C8.9788 10.7493 8.95748 10.7494 8.93617 10.7495C6.61845 10.7153 4.75012 8.82585 4.75012 6.5ZM5.826 11.2953C4.27362 10.2656 3.25013 8.50235 3.25013 6.5C3.25013 3.32436 5.82449 0.75 9.00012 0.75C12.1758 0.75 14.7501 3.32436 14.7501 6.5C14.7501 8.50235 13.7266 10.2656 12.1743 11.2953C12.7186 11.4882 13.2464 11.7312 13.7505 12.0223C15.1948 12.8562 16.3941 14.0557 17.2278 15.5001C17.4349 15.8588 17.3119 16.3175 16.9532 16.5246C16.5945 16.7316 16.1358 16.6087 15.9287 16.25C15.2266 15.0336 14.2167 14.0236 13.0004 13.3213C11.8039 12.6304 10.4492 12.2614 9.06832 12.2496C9.04562 12.2499 9.02289 12.25 9.00012 12.25C8.97736 12.25 8.95463 12.2499 8.93193 12.2496C7.55108 12.2614 6.19635 12.6304 4.99981 13.3213C3.78358 14.0236 2.77365 15.0336 2.07155 16.25C1.86448 16.6087 1.4058 16.7316 1.04706 16.5246C0.688322 16.3175 0.565373 15.8588 0.772447 15.5001C1.60619 14.0557 2.80547 12.8562 4.24975 12.0223C4.75385 11.7312 5.2816 11.4882 5.826 11.2953Z"
      />
    </svg>
  );
};
