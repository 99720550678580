import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseSyntheticEvent,
  createRef,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  RefObject,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import Select from 'react-select';
import {
  getAddjustedFormPosition,
  getCompanyOptionsList,
  handleClickOutside,
} from 'src/helpers';
import { AddButton } from '../buttons';
import { ProxyType } from './types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { proxyDataFragment } from './fragments';
import { Controller, useForm } from 'react-hook-form';
import {
  DropDownMenuPlacementEnum,
  OptionType,
  proxyStatusesOptions,
  RolesEnum,
  StatusEnum,
} from 'src/types';
import { AuthContext } from '../context.component';
import { config } from 'src/configs/config';

import styles from './store-proxy.module.css';
import {
  CompanyType,
  GET_COMPANIES_QUERY,
  GetCompaniesType,
} from '../company';
import { Checkbox, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type InputsType = {
  id?: string;
  country: string;
  host: string;
  port: number;
  user: string;
  password: string;
  status: OptionType;
  companyId: OptionType;
};

type StoreProxyMutation = {
  storeProxy: ProxyType[];
};

const STORE_PROXY_MUTATION = gql`
  mutation storeProxy($input: [StoreProxyInput!]!, $companyId: ID) {
    storeProxy(input: $input, companyId: $companyId) {
      ...ProxyData
    }
  }
  ${proxyDataFragment}
`;

const schema = yup.object().shape({
  id: yup.string().notRequired(),
  country: yup.string(),
  host: yup.string().required('Host is required'),
  port: yup.number().required('Port is required').min(0),
  user: yup.string().required('User is required'),
  password: yup.string().required('Password is required'),
  status: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .notRequired(),
});

const COMPANIES_LIMIT = 500;

export const StoreProxy = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    edit?: boolean;
    proxyId?: string | null;
    defaultValues?: Partial<ProxyType> | null;
    previewOnly?: boolean;
    onProxyStored?: (proxyData: ProxyType[]) => void;
    onClose?: () => void;
  }
>(
  (
    { edit, proxyId, defaultValues, onProxyStored, onClose },
    ref: ForwardedRef<HTMLDivElement> | undefined,
  ) => {
    const [showAddProxy, setShowAddProxy] = useState(!!edit);
    const { currentUser } = useContext(AuthContext);
    const [dropDownMenuPlacement, setDropDownMenuPlacement] =
      useState<DropDownMenuPlacementEnum>(DropDownMenuPlacementEnum.BOTTOM);
    const formRef: RefObject<HTMLDivElement> = createRef();
    const backdrop = useRef<HTMLDivElement>(null);
    const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors, isDirty },
    } = useForm<InputsType>({
      resolver: yupResolver(schema),
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        port: defaultValues?.port || 0,
        status:
          defaultValues?.status === StatusEnum.INACTIVE
            ? { value: StatusEnum.INACTIVE, label: 'Inactive' }
            : defaultValues?.status === StatusEnum.DISCONNECTED
            ? { value: StatusEnum.DISCONNECTED, label: 'Disconnected' }
            : { value: StatusEnum.ACTIVE, label: 'Active' },
        companyId: undefined,
        ...(proxyId ? { id: proxyId } : {}),
      },
    });

    const [forAgency, setForAgency] = useState(false);
    const [loadedCompaniesData, setLoadedCompaniesData] = useState<
      CompanyType[]
    >([]);
    const [lastLoadedCompanyCursor, setLastLoadedCompanyCursor] = useState<
      string | null
    >(null);
    const [companiesHasNextPage, setCompaniesHasNextPage] =
      useState<boolean>(false);

    const [storeProxy, { data, loading, error }] =
      useMutation<StoreProxyMutation>(STORE_PROXY_MUTATION);

    const {
      data: companiesData,
      loading: loadingCompaniesData,
      fetchMore: fetchMoreCompanies,
    } = useQuery<GetCompaniesType>(GET_COMPANIES_QUERY, {
      variables: { first: COMPANIES_LIMIT },
    });

    useEffect(() => {
      if (companiesData?.AgencyGroup?.getCompanies?.edges?.length) {
        const companiesEdges =
          companiesData.AgencyGroup.getCompanies.edges.map((edge) => ({
            ...edge.node,
          }));

        const lastCompanyCursor =
          companiesData?.AgencyGroup?.getCompanies?.edges[
            companiesData?.AgencyGroup?.getCompanies?.edges.length - 1
          ].cursor;
        setLastLoadedCompanyCursor(lastCompanyCursor);

        setLoadedCompaniesData((prev) => [...prev, ...companiesEdges]);
      }

      if (companiesData?.AgencyGroup?.getCompanies?.pageInfo) {
        setCompaniesHasNextPage(
          companiesData.AgencyGroup.getCompanies.pageInfo.hasNextPage,
        );
      } else {
        setCompaniesHasNextPage(false);
      }
    }, [companiesData]);

    const loadMoreCompaniesData = () => {
      if (loadingCompaniesData || !companiesHasNextPage) {
        return;
      }

      if (loadedCompaniesData.length > 0 && lastLoadedCompanyCursor) {
        fetchMoreCompanies({
          variables: {
            first: COMPANIES_LIMIT,
            after: lastLoadedCompanyCursor,
          },
        });
      }
    };

    const displayForm = () => {
      if (!showAddProxy) {
        setShowAddProxy(true);
      }
    };
    const outsideClickAction = () => {
      if (showAddProxy) {
        setShowAddProxy(false);
      }
    };
    const send = async (
      formData: InputsType,
      e?: BaseSyntheticEvent,
    ): Promise<void> => {
      e?.preventDefault();

      if (!loading) {
        await storeProxy({
          variables: {
            input: [
              {
                ...(formData.id ? { id: formData.id } : {}),
                country: formData.country,
                host: formData.host,
                port: formData.port,
                user: formData.user,
                password: formData.password,
                status: formData.status.value,
              },
            ],
            companyId:
              formData.companyId?.value && forAgency
                ? formData.companyId.value
                : null,
          },
        });
      }
    };
    const addjustFormPositions = () => {
      const currRef = (ref || formRef) as RefObject<HTMLDivElement>;

      if (!currRef || !currRef.current) {
        return;
      }

      const adjustedPos = getAddjustedFormPosition(currRef.current, 100);

      if (adjustedPos.top > 0) {
        currRef.current.style.top = `-${adjustedPos.top}px`;
      }
      if (adjustedPos.dropDownMenuPlacement !== dropDownMenuPlacement) {
        setDropDownMenuPlacement(adjustedPos.dropDownMenuPlacement);
      }
    };

    useEffect(() => {
      addjustFormPositions();

      if (!edit) {
        document.addEventListener('mousedown', (e) =>
          handleClickOutside(e, formRef, outsideClickAction),
        );

        return () => {
          document.removeEventListener('mousedown', (e) =>
            handleClickOutside(e, formRef, outsideClickAction),
          );
        };
      }
    });
    useEffect(() => {
      if (!loading && !error && data?.storeProxy) {
        if (onProxyStored) {
          onProxyStored(data.storeProxy);
        }

        if (!edit) {
          setShowAddProxy(false);
        }

        reset();
        setForAgency(false);
      }
    }, [data]);

    if (currentUser && currentUser.role == RolesEnum.CHATTER) {
      return <></>;
    }

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (backdrop?.current === e.target && onClose) {
        onClose();
      }
      setForAgency(false);
      setShowAddProxy(false);
    };

    return (
      <div className="relative">
        {!edit && (
          <AddButton
            btnText="Add proxy"
            className="mb-2.5"
            onClick={displayForm}
          />
        )}
        {showAddProxy && (
          <div className={styles.dialog}>
            <div className={styles.content} ref={ref || formRef}>
              <h3 className="fcrm-h3">
                {!edit ? 'Add new proxy' : 'Edit selected proxy'}
              </h3>
              {!loading && error && (
                <div className="flex flex-row items-start">
                  <div className="fcrm-error-i">{error.message}</div>
                </div>
              )}
              <form className="pt-5" onSubmit={handleSubmit(send)}>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Country:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Country of a proxy"
                      className="fcrm-form-d-item"
                      {...register('country')}
                    />
                    {errors && errors.country && (
                      <div className="fcrm-error-i">
                        {errors.country.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Host:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Host"
                      className="fcrm-form-d-item"
                      {...register('host')}
                    />
                    {errors && errors.host && (
                      <div className="fcrm-error-i">{errors.host.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Port:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Port"
                      type="number"
                      className="fcrm-form-d-item"
                      {...register('port')}
                    />
                    {errors && errors.port && (
                      <div className="fcrm-error-i">{errors.port.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">User:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="User"
                      className="fcrm-form-d-item"
                      {...register('user')}
                    />
                    {errors && errors.user && (
                      <div className="fcrm-error-i">{errors.user.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Password:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Password"
                      className="fcrm-form-d-item"
                      {...register('password')}
                    />
                    {errors && errors.password && (
                      <div className="fcrm-error-i">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Status:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={proxyStatusesOptions}
                          menuPlacement={dropDownMenuPlacement}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-start">
                  <div className="w-2/3 pb-4">
                    <Space>
                      <div>{'Add this proxy to selected agency'}</div>
                      <Checkbox
                        checked={forAgency}
                        onChange={(e: CheckboxChangeEvent) =>
                          setForAgency(e.target.checked)
                        }
                      />
                    </Space>
                  </div>
                </div>

                {forAgency ? (
                  <div className="flex flex-row items-start">
                    <div className="w-1/4 pt-2">Agencies:</div>
                    <div className="w-2/3 pb-4">
                      <Controller
                        name="companyId"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              !loadingCompaniesData &&
                              companiesData?.AgencyGroup?.getCompanies
                                ?.edges &&
                              companiesData?.AgencyGroup?.getCompanies?.edges
                                ?.length > 0
                                ? getCompanyOptionsList(
                                    companiesData.AgencyGroup.getCompanies.edges.map(
                                      (edge) => ({
                                        ...edge.node,
                                      }),
                                    ),
                                  )
                                : ([
                                    { value: '', label: 'LOADING...' },
                                  ] as OptionType[])
                            }
                            maxMenuHeight={config.maxDropDownMenuHeight}
                            menuPlacement={dropDownMenuPlacement}
                            onMenuScrollToBottom={() => {
                              loadMoreCompaniesData();
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}

                {(!edit || isDirty) && (
                  <button
                    type="submit"
                    className="fcrm-submit w-28 bg-blue-500 py-2.5 my-1.5 rounded-full m-auto hover:bg-blue-600 active:bg-blue-700">
                    Save
                  </button>
                )}
              </form>
            </div>
            <div
              className={styles.backdrop}
              ref={backdrop}
              onClick={handleClose}
            />
          </div>
        )}
      </div>
    );
  },
);
