import { URL_KEY } from "../constants";
import { GetAppSettingsFetchType, SettingsType } from "../types";

export const dumpConfig = (
  configResponse: GetAppSettingsFetchType,
  updateFunc: React.Dispatch<React.SetStateAction<SettingsType[] | null>>,
) => {
  const urlSettings = configResponse.getAppSettings.find(
    (setting) => setting.key === URL_KEY,
  );
  if (urlSettings) {
    const prepearedUrlSettings = {
      ...urlSettings,
      value:
        urlSettings?.value && typeof urlSettings.value === 'string'
          ? JSON.parse(urlSettings?.value)
          : [],
    };
    const prepearedConfig = configResponse.getAppSettings.map((setting) => {
      if (setting.key === URL_KEY) {
        return prepearedUrlSettings;
      } else {
        return setting;
      }
    });
    updateFunc(prepearedConfig);
  }
};
