import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql, useMutation, useQuery } from '@apollo/client';
import Select from 'react-select';
import {
  BaseSyntheticEvent,
  createRef,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AddButton } from '../buttons';
import { GET_COMPANIES_QUERY } from './queries';
import { GetCompaniesType, UserCompanyType } from './types';
import { Controller, useForm } from 'react-hook-form';
import { DropDownMenuPlacementEnum, OptionType, RolesEnum } from 'src/types';
import {
  getAddjustedFormPosition,
  getCompanyOptionsList,
  handleClickOutside,
  userHasOneOfRole,
} from 'src/helpers';
import { userDataFragment, UserType } from '../user';
import { companyDataFragment } from './fragments';
import { AuthContext } from '../context.component';
import { config } from 'src/configs/config';

//@ts-ignore
import styles from './add-user-company.module.css';
import { Button } from 'antd';
import { CloseWhiteIcon } from 'src/icons';

const COMPANIES_LIMIT = 500;

type AddUserToCompanyMutation = {
  addUserToCompany: UserType;
};

type RemoveUserFromCompanyMutation = {
  removeUserFromCompany: UserType;
};

const ADD_USER_TO_COMPANY_MUTATION = gql`
  mutation addUserToCompany($input: AddUserToCompanyInput!) {
    addUserToCompany(input: $input) {
      ...UserData
      company {
        ...CompanyData
      }
    }
  }
  ${userDataFragment}
  ${companyDataFragment}
`;

const REMOVE_USER_FROM_COMPANY_MUTATION = gql`
  mutation removeUserFromCompany($input: RemoveUserFromCompanyInput!) {
    removeUserFromCompany(input: $input) {
      ...UserData
      company {
        ...CompanyData
      }
    }
  }
  ${userDataFragment}
  ${companyDataFragment}
`;

type InputsType = {
  companyId: OptionType;
  userId: string;
};

const schema = yup.object().shape({
  id: yup.string().notRequired(),
  userId: yup.string().required('User is required'),
  companyId: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required('Company is required'),
});

export const AddUserCompany = ({
  userId,
  userName,
  companies,
}: UserCompanyType) => {
  const [showAddUserToCompany, setShowAddUserToCompany] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [dropDownMenuPlacement, setDropDownMenuPlacement] =
    useState<DropDownMenuPlacementEnum>(DropDownMenuPlacementEnum.BOTTOM);
  const { currentUser } = useContext(AuthContext);
  const [newUserCompany, setNewUserCompany] = useState<string | null>(null);

  const { data, loading, error } = useQuery<GetCompaniesType>(
    GET_COMPANIES_QUERY,
    {
      variables: { first: COMPANIES_LIMIT },
    },
  );
  const [
    addUserToCompany,
    {
      data: addUserToCompanyData,
      loading: addUserToCompanyLoading,
      error: addUserToCompanyError,
    },
  ] = useMutation<AddUserToCompanyMutation>(ADD_USER_TO_COMPANY_MUTATION);
  const [
    removeUserFromCompany,
    {
      data: removeUserFromCompanyData,
      loading: removeUserFromCompanyLoading,
      error: removeUserFromCompanyError,
    },
  ] = useMutation<RemoveUserFromCompanyMutation>(
    REMOVE_USER_FROM_COMPANY_MUTATION,
  );
  const ref: RefObject<HTMLDivElement> = createRef();
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<InputsType>({
    resolver: yupResolver(schema),
    defaultValues: {
      userId,
    },
  });

  const displayForm = () => {
    if (!showAddUserToCompany) {
      setShowAddUserToCompany(true);
    }
  };

  const outsideClickAction = () => {
    if (showAddUserToCompany) {
      setShowAddUserToCompany(false);
    }
  };
  const addjustFormPositions = () => {
    if (!ref.current) {
      return;
    }

    const adjustedPos = getAddjustedFormPosition(ref.current);

    if (adjustedPos.top > 0) {
      ref.current.style.top = `-${adjustedPos.top}px`;
    }
    if (adjustedPos.dropDownMenuPlacement !== dropDownMenuPlacement) {
      setDropDownMenuPlacement(adjustedPos.dropDownMenuPlacement);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, ref, outsideClickAction),
    );
    window.addEventListener('resize', addjustFormPositions);

    addjustFormPositions();

    if (showEdit && companies.length) {
      const resCompany = getCompanyOptionsList(companies || []);

      if (resCompany && resCompany.length) {
        setValue('companyId', resCompany[0]);
      }
    }

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, ref, outsideClickAction),
      );
      window.removeEventListener('resize', addjustFormPositions);
    };
  });

  useEffect(() => {
    const formData = getValues();

    if (
      (!addUserToCompanyLoading &&
        !addUserToCompanyError &&
        addUserToCompanyData?.addUserToCompany) ||
      (!removeUserFromCompanyLoading &&
        !removeUserFromCompanyError &&
        removeUserFromCompanyData?.removeUserFromCompany)
    ) {
      if (removeUserFromCompanyData?.removeUserFromCompany) {
        setNewUserCompany(null);
      } else {
        setNewUserCompany(formData?.companyId?.label);
      }

      reset();
      setShowAddUserToCompany(false);
      setShowEdit(false);
    }
  }, [addUserToCompanyData, removeUserFromCompanyData]);

  const send = async (): Promise<void> => {
    if (!addUserToCompanyLoading) {
      const formData = getValues();

      await addUserToCompany({
        variables: {
          input: {
            companyId: formData.companyId.value,
            userId: formData.userId,
            ...(showEdit ? { moveOnly: true } : {}),
          },
        },
      });
    }
  };
  const removeUser = async (targetCompanyId?: string): Promise<void> => {
    if (!removeUserFromCompanyLoading && targetCompanyId) {
      await removeUserFromCompany({
        variables: {
          input: {
            userId,
            companyId: targetCompanyId,
          },
        },
      });
    }
  };

  const submitData = async (
    _: InputsType,
    e?: BaseSyntheticEvent,
  ): Promise<void> => {
    e?.preventDefault();

    send();
  };

  return (
    <div className="relative">
      {(!companies.length || showEdit) && (
        <>
          {!newUserCompany &&
            !showEdit &&
            currentUser &&
            !userHasOneOfRole(currentUser, [RolesEnum.CHATTER]) && (
              <div className="flex flex-row items-center justify-end">
                <AddButton
                  btnText="Add"
                  className="mb-2.5"
                  onClick={displayForm}
                />
              </div>
            )}
          {showAddUserToCompany && (
            <div className={styles.dialog}>
              <div className={styles.content} ref={ref}>
                <h3 className="fcrm-h3">Add a user to the company</h3>
                <div>
                  <span className="text-xs">user:</span> {userName}
                </div>
                {!loading && error && (
                  <div className="fcrm-error-i">{error.message}</div>
                )}
                {!addUserToCompanyLoading && addUserToCompanyError && (
                  <div className="fcrm-error-i">
                    {addUserToCompanyError.message}
                  </div>
                )}
                {!removeUserFromCompanyLoading &&
                  removeUserFromCompanyError && (
                    <div className="fcrm-error-i">
                      {removeUserFromCompanyError.message}
                    </div>
                  )}
                {!loading && data && (
                  <form className="pt-5" onSubmit={handleSubmit(submitData)}>
                    <Controller
                      name="companyId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getCompanyOptionsList(
                            data.AgencyGroup.getCompanies.edges.map(
                              (edge) => ({
                                ...edge.node,
                              }),
                            ),
                          )}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                          onChange={(e) => {
                            if (e) {
                              setValue('companyId', e);
                            }

                            send();
                          }}
                        />
                      )}
                    />
                    {errors && errors.companyId && (
                      <div className="fcrm-error-i">
                        {errors.companyId.message}
                      </div>
                    )}
                  </form>
                )}
              </div>

              <div
                className={styles.backdrop}
                onClick={() => setShowAddUserToCompany(false)}
              />
            </div>
          )}
        </>
      )}
      {!!companies.length &&
        companies.map((company) => (
          <div
            className="flex flex-row items-center justify-end pb-1.5"
            key={`company:${company.id}`}>
            <div
              className="hover:cursor-pointer hover:underline"
              onClick={(e) => {
                e.preventDefault();

                if (
                  !currentUser ||
                  userHasOneOfRole(currentUser, [RolesEnum.CHATTER])
                ) {
                  return;
                }

                setShowEdit(true);
                setShowAddUserToCompany(true);
              }}>
              {company.name}
            </div>
            {(!!company.id &&
              currentUser &&
              !userHasOneOfRole(currentUser, [RolesEnum.CHATTER]) &&
              currentUser &&
              currentUser?.id !== userId && (
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();

                    removeUser(company.id);
                  }}
                  shape="circle"
                  danger
                  icon={<CloseWhiteIcon />}
                />
              )) || <div className="w-[35px] ml-2"></div>}
          </div>
        ))}
    </div>
  );
};
