import { SVGProps } from 'react';

export const ListsIcon = ({
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 18;
  const resHeight = height || 12;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 0C0.947715 0 0.5 0.447715 0.5 1C0.5 1.55228 0.947715 2 1.5 2H1.50833C2.06062 2 2.50833 1.55228 2.50833 1C2.50833 0.447715 2.06062 0 1.50833 0H1.5ZM5.66626 0.25C5.25205 0.25 4.91626 0.585786 4.91626 1C4.91626 1.41421 5.25205 1.75 5.66626 1.75H16.4996C16.9138 1.75 17.2496 1.41421 17.2496 1C17.2496 0.585786 16.9138 0.25 16.4996 0.25H5.66626ZM5.66626 5.25C5.25205 5.25 4.91626 5.58579 4.91626 6C4.91626 6.41421 5.25205 6.75 5.66626 6.75H16.4996C16.9138 6.75 17.2496 6.41421 17.2496 6C17.2496 5.58579 16.9138 5.25 16.4996 5.25H5.66626ZM4.91626 11C4.91626 10.5858 5.25205 10.25 5.66626 10.25H16.4996C16.9138 10.25 17.2496 10.5858 17.2496 11C17.2496 11.4142 16.9138 11.75 16.4996 11.75H5.66626C5.25205 11.75 4.91626 11.4142 4.91626 11ZM0.5 6C0.5 5.44772 0.947715 5 1.5 5H1.50833C2.06062 5 2.50833 5.44772 2.50833 6C2.50833 6.55228 2.06062 7 1.50833 7H1.5C0.947715 7 0.5 6.55228 0.5 6ZM1.5 10C0.947715 10 0.5 10.4477 0.5 11C0.5 11.5523 0.947715 12 1.5 12H1.50833C2.06062 12 2.50833 11.5523 2.50833 11C2.50833 10.4477 2.06062 10 1.50833 10H1.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
