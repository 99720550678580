import { gql } from '@apollo/client';

export const companyDataFragment = gql`
  fragment CompanyData on Company {
    id
    name
    contactEmail
    address
    status
    websiteUrl
    creatorsCount
    telegramNumber
  }
`;
