import { SVGProps } from 'react';

export const Logo = ({
  id,
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id={id}
      width={width || 188}
      height={height || 40}
      viewBox="0 0 188 40"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.2372 13H55.1937V18.6471H63.8494V21.3529H55.1937V29.7059H52V10.2941H66.2372V13Z"
        fill="black"
      />
      <path
        d="M76.3704 20.2647C76.3704 19.2843 76.0918 18.5294 75.5347 18C74.9974 17.451 74.2513 17.1765 73.2961 17.1765C72.4007 17.1765 71.6346 17.3824 70.9979 17.7941C70.3611 18.2059 69.8935 18.8725 69.5951 19.7941L66.9088 18.6765C67.2869 17.4804 68.0231 16.5196 69.1175 15.7941C70.2318 15.0686 71.6645 14.7059 73.4155 14.7059C74.6094 14.7059 75.664 14.902 76.5794 15.2941C77.4947 15.6667 78.211 16.2353 78.7284 17C79.2656 17.7647 79.5343 18.7451 79.5343 19.9412V26.5C79.5343 27.2255 79.9024 27.5882 80.6386 27.5882C80.9968 27.5882 81.345 27.5392 81.6833 27.4412L81.5042 29.6176C81.0465 29.8333 80.4695 29.9412 79.773 29.9412C79.1363 29.9412 78.5592 29.8333 78.0419 29.6176C77.5444 29.3824 77.1465 29.0392 76.848 28.5882C76.5694 28.1176 76.4301 27.5392 76.4301 26.8529V26.6471L77.0868 26.6176C76.9077 27.3824 76.5396 28.0196 75.9824 28.5294C75.4253 29.0196 74.7786 29.3922 74.0423 29.6471C73.3061 29.8824 72.5599 30 71.8038 30C70.8685 30 70.0229 29.8529 69.2667 29.5588C68.5305 29.2647 67.9435 28.8235 67.5057 28.2353C67.0879 27.6275 66.8789 26.8824 66.8789 26C66.8789 24.902 67.2371 24 67.9534 23.2941C68.6897 22.5882 69.7244 22.1078 71.0576 21.8529L76.848 20.7353L76.8181 22.9412L72.6096 23.7941C71.7739 23.951 71.1471 24.1765 70.7293 24.4706C70.3114 24.7647 70.1025 25.1961 70.1025 25.7647C70.1025 26.3137 70.3114 26.7549 70.7293 27.0882C71.167 27.402 71.7441 27.5588 72.4604 27.5588C72.9579 27.5588 73.4354 27.5 73.8931 27.3824C74.3706 27.2647 74.7985 27.0784 75.1765 26.8235C75.5546 26.549 75.8431 26.2059 76.0421 25.7941C76.261 25.3627 76.3704 24.8529 76.3704 24.2647V20.2647Z"
        fill="black"
      />
      <path
        d="M84.1699 29.7059V15H87.0054L87.2144 18.8235L86.7965 18.4118C87.0751 17.5686 87.473 16.8725 87.9904 16.3235C88.5277 15.7549 89.1544 15.3431 89.8708 15.0882C90.607 14.8333 91.3831 14.7059 92.1989 14.7059C93.2535 14.7059 94.1987 14.9118 95.0344 15.3235C95.89 15.7157 96.5666 16.3137 97.064 17.1176C97.5615 17.9216 97.8102 18.9412 97.8102 20.1765V29.7059H94.6165V21.1471C94.6165 19.6961 94.3081 18.6961 93.6913 18.1471C93.0943 17.5784 92.3382 17.2941 91.4229 17.2941C90.7662 17.2941 90.1295 17.451 89.5126 17.7647C88.8958 18.0588 88.3784 18.5392 87.9606 19.2059C87.5626 19.8725 87.3636 20.7647 87.3636 21.8824V29.7059H84.1699Z"
        fill="black"
      />
      <path
        d="M107.724 30C106.231 30 104.868 29.7157 103.635 29.1471C102.401 28.5784 101.426 27.7745 100.71 26.7353L102.918 24.8529C103.356 25.6569 103.983 26.3039 104.799 26.7941C105.634 27.2647 106.629 27.5 107.783 27.5C108.699 27.5 109.425 27.3431 109.962 27.0294C110.5 26.7157 110.768 26.2745 110.768 25.7059C110.768 25.3333 110.639 25.0098 110.38 24.7353C110.141 24.4412 109.674 24.2157 108.977 24.0588L105.843 23.4118C104.251 23.098 103.117 22.598 102.441 21.9118C101.764 21.2059 101.426 20.3137 101.426 19.2353C101.426 18.4314 101.665 17.6863 102.142 17C102.62 16.3137 103.316 15.7647 104.232 15.3529C105.167 14.9216 106.281 14.7059 107.575 14.7059C109.067 14.7059 110.34 14.9706 111.395 15.5C112.45 16.0098 113.255 16.7451 113.813 17.7059L111.604 19.5294C111.226 18.7451 110.669 18.1667 109.932 17.7941C109.196 17.402 108.43 17.2059 107.634 17.2059C106.997 17.2059 106.45 17.2843 105.993 17.4412C105.535 17.598 105.187 17.8137 104.948 18.0882C104.709 18.3627 104.59 18.6863 104.59 19.0588C104.59 19.451 104.739 19.7941 105.037 20.0882C105.336 20.3824 105.873 20.6078 106.649 20.7647L110.052 21.4706C111.485 21.7451 112.489 22.2157 113.066 22.8824C113.663 23.5294 113.962 24.3235 113.962 25.2647C113.962 26.2059 113.713 27.0392 113.216 27.7647C112.718 28.4706 112.002 29.0196 111.067 29.4118C110.131 29.8039 109.017 30 107.724 30Z"
        fill="black"
      />
      <path
        d="M125.402 30C123.711 30 122.199 29.6667 120.865 29C119.532 28.3333 118.478 27.3725 117.702 26.1176C116.926 24.8627 116.538 23.3627 116.538 21.6176C116.538 19.9706 116.776 18.451 117.254 17.0588C117.731 15.6471 118.428 14.4118 119.343 13.3529C120.259 12.2941 121.373 11.4706 122.686 10.8824C124.019 10.2941 125.542 10 127.253 10C128.725 10 130.039 10.2451 131.193 10.7353C132.347 11.2255 133.292 11.9412 134.028 12.8824C134.764 13.8039 135.252 14.951 135.491 16.3235L130.566 17.4118C130.446 16.2353 130.108 15.3725 129.551 14.8235C128.994 14.2549 128.178 13.9706 127.104 13.9706C126.188 13.9706 125.392 14.2157 124.716 14.7059C124.039 15.1765 123.472 15.7941 123.014 16.5588C122.557 17.3235 122.219 18.1569 122 19.0588C121.801 19.9608 121.701 20.8431 121.701 21.7059C121.701 22.6078 121.86 23.3824 122.179 24.0294C122.497 24.6569 122.955 25.1471 123.552 25.5C124.169 25.8529 124.895 26.0294 125.731 26.0294C126.745 26.0294 127.541 25.7745 128.118 25.2647C128.695 24.7353 129.183 24.049 129.581 23.2059L134.177 24.9706C133.322 26.5196 132.188 27.7451 130.775 28.6471C129.362 29.549 127.571 30 125.402 30Z"
        fill="black"
      />
      <path
        d="M135.147 29.7059L139.713 10.2941H148.16C150.19 10.2941 151.792 10.7843 152.966 11.7647C154.16 12.7255 154.757 14.0784 154.757 15.8235C154.757 17.2353 154.418 18.4314 153.742 19.4118C153.085 20.3922 152.19 21.1569 151.055 21.7059C149.921 22.2353 148.638 22.5294 147.205 22.5882L146.071 22.8529H141.355L142.191 19.2647H146.101C146.877 19.2647 147.523 19.1373 148.041 18.8824C148.578 18.6275 148.986 18.2745 149.265 17.8235C149.543 17.3529 149.682 16.8431 149.682 16.2941C149.682 15.5294 149.424 14.9804 148.906 14.6471C148.389 14.2941 147.722 14.1176 146.907 14.1176H143.862L140.191 29.7059H135.147ZM147.892 29.7059L144.668 21.2941L149.324 20.5882L153.085 29.7059H147.892Z"
        fill="black"
      />
      <path
        d="M154.749 29.7059L159.316 10.2941H165.793L166.748 19.7059L167.017 24.5H167.046L169.434 19.7059L174.658 10.2941H182L177.433 29.7059H172.897L174.986 20.7941L176.389 15.0588H176.329L168.061 29.7059H164.121L162.51 15.1765H162.45L161.375 20.7941L159.286 29.7059H154.749Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5354 9.22141C16.9508 8.6351 18.4679 8.33333 20 8.33333C21.5321 8.33333 23.0492 8.6351 24.4646 9.22141C25.1016 9.48522 25.7123 9.80433 26.2901 10.1742L32.2634 4.20097C30.8562 3.10869 29.3064 2.20698 27.6537 1.52241C25.2272 0.517316 22.6264 0 20 0C17.3736 0 14.7728 0.517315 12.3463 1.52241C9.91982 2.5275 7.71504 4.00069 5.85787 5.85786C4.00069 7.71503 2.5275 9.91982 1.52241 12.3463C0.517316 14.7728 0 17.3736 0 20C0 22.6264 0.517315 25.2272 1.52241 27.6537C2.5275 30.0802 4.00069 32.285 5.85786 34.1421C7.71503 35.9993 9.91982 37.4725 12.3463 38.4776C14.7728 39.4827 17.3736 40 20 40C22.6264 40 25.2272 39.4827 27.6537 38.4776C29.3064 37.793 30.8562 36.8913 32.2635 35.799L26.2902 29.8257C25.7123 30.1956 25.1016 30.5148 24.4646 30.7786C23.0492 31.3649 21.5321 31.6667 20 31.6667C18.4679 31.6667 16.9508 31.3649 15.5354 30.7786C14.1199 30.1923 12.8338 29.3329 11.7504 28.2496C10.6671 27.1662 9.80771 25.8801 9.22141 24.4646C8.6351 23.0492 8.33333 21.5321 8.33333 20C8.33333 18.4679 8.6351 16.9508 9.22141 15.5354C9.80771 14.1199 10.6671 12.8338 11.7504 11.7504C12.8338 10.6671 14.1199 9.80771 15.5354 9.22141ZM29.8257 26.2902L35.799 32.2634C36.8913 30.8562 37.793 29.3064 38.4776 27.6537C39.4827 25.2272 40 22.6264 40 20C40 17.3736 39.4827 14.7728 38.4776 12.3463C37.793 10.6936 36.8913 9.14372 35.7989 7.73649L29.8257 13.7098C30.1956 14.2876 30.5148 14.8984 30.7786 15.5354C31.3649 16.9508 31.6667 18.4679 31.6667 20C31.6667 21.5321 31.3649 23.0492 30.7786 24.4646C30.5148 25.1016 30.1957 25.7123 29.8257 26.2902Z"
        fill="black"
      />
      <path
        d="M29.8258 26.2902L35.7991 32.2635C36.8914 30.8563 37.7931 29.3064 38.4777 27.6537C39.4828 25.2272 40.0001 22.6265 40.0001 20.0001C40.0001 17.3736 39.4828 14.7729 38.4777 12.3464C37.7931 10.6937 36.8914 9.14381 35.7991 7.73659L29.8258 13.7099C30.1957 14.2877 30.5149 14.8985 30.7787 15.5354C31.365 16.9509 31.6668 18.468 31.6668 20.0001C31.6668 21.5321 31.365 23.0492 30.7787 24.4647C30.5149 25.1016 30.1958 25.7124 29.8258 26.2902Z"
        fill="white"
      />
      <path
        d="M29.8258 26.2902L35.7991 32.2635C36.8914 30.8563 37.7931 29.3064 38.4777 27.6537C39.4828 25.2272 40.0001 22.6265 40.0001 20.0001C40.0001 17.3736 39.4828 14.7729 38.4777 12.3464C37.7931 10.6937 36.8914 9.14381 35.7991 7.73659L29.8258 13.7099C30.1957 14.2877 30.5149 14.8985 30.7787 15.5354C31.365 16.9509 31.6668 18.468 31.6668 20.0001C31.6668 21.5321 31.365 23.0492 30.7787 24.4647C30.5149 25.1016 30.1958 25.7124 29.8258 26.2902Z"
        fill="url(#paint0_radial_537_10711)"
        fillOpacity="0.5"
      />
      <path
        d="M29.8258 26.2902L35.7991 32.2635C36.8914 30.8563 37.7931 29.3064 38.4777 27.6537C39.4828 25.2272 40.0001 22.6265 40.0001 20.0001C40.0001 17.3736 39.4828 14.7729 38.4777 12.3464C37.7931 10.6937 36.8914 9.14381 35.7991 7.73659L29.8258 13.7099C30.1957 14.2877 30.5149 14.8985 30.7787 15.5354C31.365 16.9509 31.6668 18.468 31.6668 20.0001C31.6668 21.5321 31.365 23.0492 30.7787 24.4647C30.5149 25.1016 30.1958 25.7124 29.8258 26.2902Z"
        fill="url(#paint1_radial_537_10711)"
        fillOpacity="0.5"
      />
      <defs>
        <radialGradient
          id="paint0_radial_537_10711"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.8258 32.2635) rotate(-67.4703) scale(26.5534 46.4139)">
          <stop stopColor="#660B7D" />
          <stop offset="1" stopColor="#4F00F7" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_537_10711"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0001 7.73657) rotate(112.53) scale(26.5534 46.4139)">
          <stop stopColor="#0276A8" />
          <stop offset="1" stopColor="#0213A8" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
