import { SVGProps } from 'react';

export const CloseWhiteIcon = ({
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 20;
  const resHeight = height || 20;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.625 4.375L4.375 15.625M15.625 15.625L4.375 4.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
