import { OptionType } from './option.type';

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISCONNECTED = 'DISCONNECTED',
}

export type StatusUnion = `${StatusEnum}`;

export const statusesArr = Object.values(StatusEnum);

export const statusesOptions: OptionType[] = statusesArr.map((status) => {
  const resLabel = status.toLowerCase();

  return {
    value: status,
    label: resLabel[0].toUpperCase() + resLabel.slice(1),
  };
});
