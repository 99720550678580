import { GlobalId } from '../types/global-id.type';
import { unbase64Helper } from './unbase64.helper';

export const fromGlobalIdHelper = (globalId: string): GlobalId => {
  const unbasedGlobalId: string = unbase64Helper(globalId);
  const delimiterPos: number = unbasedGlobalId.indexOf(':');

  const resId = unbasedGlobalId.substring(delimiterPos + 1);
  const resNumberId = Number(resId);

  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: !Number.isNaN(resNumberId) ? resNumberId : resId,
  };
};