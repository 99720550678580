export enum ModelActivityEnum {
  LOST = 'LOST',
  NOT_ACTIVE = 'NOT_ACTIVE',
  ACTIVE = 'ACTIVE',
}

export const getModelActivity = (
  lastActivity: string | null,
): ModelActivityEnum => {
  if (!lastActivity) {
    return ModelActivityEnum.LOST;
  }
  const today = new Date();
  const activityDate = new Date(lastActivity);
  const differenceInDays = Math.floor(
    (today.getTime() - activityDate.getTime()) / (1000 * 60 * 60 * 24),
  );

  if (differenceInDays <= 3) {
    return ModelActivityEnum.ACTIVE;
  } else if (differenceInDays <= 7) {
    return ModelActivityEnum.NOT_ACTIVE;
  } else {
    return ModelActivityEnum.LOST;
  }
};

export const geUserActivity = (
  count: number,
  lastActivitiesTime: string[],
): ModelActivityEnum => {
  if (!count) {
    return ModelActivityEnum.LOST;
  }

  let countActive = 0;

  for (const lastActivityTime of lastActivitiesTime) {
    if (getModelActivity(lastActivityTime) === ModelActivityEnum.ACTIVE) {
      countActive++;
    }
  }

  const percent = countActive / count;

  if (percent > 0.5) {
    return ModelActivityEnum.ACTIVE;
  }
  if (percent > 0.3) {
    return ModelActivityEnum.NOT_ACTIVE;
  }
  return ModelActivityEnum.LOST;
};

export const geUserFirstApproveTime = (
  lastApprovesTime: (string | null)[],
): Date | null => {
  if (!lastApprovesTime.length) {
    return null;
  }

  let minDate: Date | null = null;
  for (const lastApproveTime of lastApprovesTime) {
    if (!lastApproveTime) {
      continue;
    }

    const date = new Date(lastApproveTime);
    if (minDate === null || date < minDate) {
      minDate = date;
    }
  }
  return minDate;
};
