export function getCollectingStatusIcon(
    startDate: string | null,
    endDate: string | null,
  ) {
    if (endDate) {
      return '\u{2705}'; // success icon
    }
    if (!startDate && !endDate) {
      return '\u{1F55E}'; // waiting icon
    }
    if (startDate && !endDate) {
      return '\u{23EC}'; // collecting icon
    }
    return '';
  }