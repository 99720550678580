import { GqlFetchType, OptionType } from 'src/types';

export enum PlatformEnum {
  UNKNOWN = 'UNKNOWN',
  WINDOWS = 'WINDOWS',
  MAC_OS = 'MAC_OS',
  ANDROID = 'ANDROID',
}

export type PlatformUnion = `${PlatformEnum}`;

export type PlatformType = {
  id: string;
  platform: PlatformUnion;
  userAgent: string;
  secChUa: string;
  secChUaFullVersionList: string;
  secChUaPlatform: string;
  secChUaModel: string;
  secChUaPlatformVersion: string;
  secChUaMobile: string;
};

export type GetPlatformsType = {
  Dictionary: {
    getPlatforms: GqlFetchType<PlatformType>;
  };
};

export const platformsArr = Object.values(PlatformEnum);

export const platformsOptions: OptionType[] = platformsArr.map((platform) => {
  const resLabel = platform.toLowerCase();

  return {
    value: platform,
    label:
      platform === PlatformEnum.MAC_OS
        ? 'MacOS'
        : resLabel[0].toUpperCase() + resLabel.slice(1),
  };
});
