import { memo, useEffect, useState } from 'react';
import styles from './style.module.css';
import { useDebouncedValue } from 'src/hooks/useDebounceValue.hook';
import useUpdateEffect from 'src/hooks/useUpdateEffect.hook';
import Input from 'antd/es/input/Input';

interface TableHeaderProps {
  title: string;
  search?: boolean;
  name?: string;
  searchValue?: string;
  setSearch?: (key: string, value: string) => void;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  name,
  search,
  searchValue = '',
  setSearch,
}) => {
  const [value, setValue] = useState<string>(searchValue);

  const debouncedValue = useDebouncedValue(value, 500);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useUpdateEffect(() => {
    if (name && setSearch && searchValue !== debouncedValue) {
      setSearch(name, debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!searchValue) {
      setValue('');
    }
  }, [searchValue]);

  return (
    <div className={styles.wrapper}>
      <div>{title}</div>
      {search && (
        <div onClick={onClick}>
          <Input
            className={styles.input}
            value={value}
            onChange={onChange}
            placeholder={`Search by ${title}`}
          />
        </div>
      )}
    </div>
  );
};

export default memo(
  TableHeader,
  (prev, next) => prev.searchValue === next.searchValue,
);
