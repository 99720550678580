import { useMutation } from '@apollo/client';
import { Button, ConfigProvider, Space, Tag } from 'antd';

import { createStyles } from 'antd-style';
import { STORE_USER_MUTATION, StoreUserMutation, UserType } from '../user';
import { FC } from 'react';

const useStyle = createStyles(({ prefixCls, css }) => ({
  customButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      background-color: #2e7d32;
      border-color: #2e7d32;
      color: #fff;
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: '';
        background-color: #2e7d32;
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover {
        background-color: rgb(27, 94, 32);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
          rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
      }
    }
  `,
}));

interface IProps {
  userData: UserType;
  onUpdate: () => Promise<void>;
}

export const RowStatus: FC<IProps> = ({userData, onUpdate}) => {
  const { styles } = useStyle();
  const [storeUser] = useMutation<StoreUserMutation>(
    STORE_USER_MUTATION,
  );

  const handleApprove = async () => {
    const response = await storeUser({
      variables: {
        input: {
          id: userData.id,
          status: 'ACTIVE',
          username: userData.username,
          email: userData.email
        },
      },
    });

    if (response.data?.storeUser.id) {
      onUpdate();
    }
  };

  return (
    <>
      <ConfigProvider
        button={{
          className: styles.customButton,
        }}>
        {userData .status === 'ACTIVE' ? (
          <Tag color="success">ACTIVE</Tag>
        ) : (
          <Space size="small">
            <Tag color="error">{userData.status}</Tag>
            <Button size={'small'} type="primary" onClick={handleApprove}>
              Approve
            </Button>
          </Space>
        )}
      </ConfigProvider>
    </>
  );
};
