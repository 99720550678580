import { SVGProps } from 'react';

export const PlusIcon = ({
  width,
  height,
  className,
  stroke,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 16;
  const resHeight = height || 16;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 8H13.5M8 2.5V13.5"
        stroke={stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
