import { useEffect, useState }  from 'react';
import cn                       from 'classnames';
import { TabType }              from './types';

interface TabsProps {
  activeTab ?: string
  tabs : TabType[],
  handleChange ?: (tab : string) => void
}

export const Tabs : React.FC<TabsProps> = ({
  activeTab,
  tabs,
  handleChange
}) => {
  const [tabsState, setTabsState] = useState<string>(activeTab || tabs[0].id);

  const switchContent = (tab : string) => {
    if (handleChange) handleChange(tab)
    setTabsState(tab)
  };

  useEffect(() => {
    setTabsState(activeTab || tabs[0].id)
  }, [activeTab])

  return (
    <>
      <header className="flex flex-row items-start pb-2.5">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => switchContent(tab.id)}
            className={cn({
              'fcrm-tab-active' : tab.id === tabsState,
              'fcrm-tab': tab.id !== tabsState,
            })}>
            {tab.title}
          </div>
        ))}
      </header>
      {
        tabs?.find(({id}) => tabsState === id)?.content
      }
    </>
  );
};
