import { gql } from '@apollo/client';
import { platformDataFragment } from './fragments';

export const GET_PLATFORMS_QUERY = gql`
  query getPlatforms(
    $before: ID
    $after: ID
    $first: Int
    $last: Int
    $platform: PlatformEnum
  ) {
    Dictionary {
      getPlatforms(
        before: $before
        after: $after
        first: $first
        last: $last
        platform: $platform
      ) {
        edges {
          node {
            ...PlatformData
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${platformDataFragment}
`;
