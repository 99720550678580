import { useContext } from 'react';
import { AuthContext } from '../context.component';
import { StoreUser } from './store-user.component';
import { UserType } from './types';

export const EditMyProfile = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const onUserStored = (userData: UserType) => {
    setCurrentUser({ ...currentUser, ...userData });
  };

  return (
    <div className="w-full bg-white relative p-2.5">
      <h3 className="fcrm-h3 mb-4">My profile</h3>
      {currentUser?.id && (
        <StoreUser
          userId={currentUser.id}
          edit
          previewOnly
          defaultValues={{
            ...currentUser,
          }}
          className="border-none drop-shadow-none"
          onUserStored={onUserStored}
        />
      )}
    </div>
  );
};
