import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ModelStatusEnum, RolesEnum } from 'src/types';
import { AuthContext } from '../context.component';
import { EditChatModel } from './edit-chat-model.component';
import { GET_CHAT_MODELS_QUERY, GET_PUBLIC_KEY_QUERY } from './queries';
import { ChatModelType, GetChatModelByPageType } from './types';
import Table from '../table';
import { dumpModelsSortParams } from 'src/utils/dump-sort-params.utils';
import { dumpModels } from 'src/helpers/dump.helper';
import { StoreChatModel } from './store-chat-model.component';
import { RowChatters } from './RowChatters';

import styles from './Models.module.css';
import { CREATOR_STATUSES } from 'src/constants/creators.constants';
import TableHeader from '../base/tableHeader';
import { checkSortOrder } from 'src/utils/sort.utils';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { ChatModelSearchEnum } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import { Tag } from 'antd';

const MODELS_PAGINATION_LIMIT = 10;

export const AllChatModelsList = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    setPageParams,
    setPaginationLimitParams,
    setSortParams,
    setFilterParams,
    sellsSearch,
    setSellsSearchParams,
    filters,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, pageData, loading, refetch, error, updateData } = useQueryHook<
    GetChatModelByPageType,
    ChatModelType
  >({
    query: GET_CHAT_MODELS_QUERY,
    variables: {
      limit: paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...filters,
      ...dumpModelsSortParams({ field: sortField, order: sortOrder }),
      ...prepareSearchBySells(sellsSearch),
    },
    dumpFunction: dumpModels,
    fetchPolicy: 'network-only',
  });

  const { data: dataPublicKey } = useQuery<{
    getPubKey: string | null;
  }>(GET_PUBLIC_KEY_QUERY, {
    skip: currentUser?.role !== RolesEnum.SUPER_ADMIN,
  });

  const COLUMNS = [
    {
      key: 'name',
      dataIndex: 'name',
      title: (
        <TableHeader
          title={'Creator name'}
          search
          key={ChatModelSearchEnum.NAME}
          name={ChatModelSearchEnum.NAME}
          searchValue={sellsSearch?.[ChatModelSearchEnum.NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '25%',
      sorter: true,
      sortOrder: checkSortOrder('name', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, model: ChatModelType) => {
        return (
          <EditChatModel
            className="fcrm-greed-col"
            modelData={{
              ...model,
            }}
            publicKey={dataPublicKey?.getPubKey || null}
            updateCreatorInList={updateData}
          />
        );
      },
    },
    {
      key: 'modelId',
      dataIndex: 'modelId',
      title: 'Creators Id',
      width: '12%',
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: (
        <TableHeader
          title={'Email'}
          search
          key={ChatModelSearchEnum.EMAIL}
          name={ChatModelSearchEnum.EMAIL}
          searchValue={sellsSearch?.[ChatModelSearchEnum.EMAIL]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '12%',
      sorter: true,
      sortOrder: checkSortOrder('email', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'company',
      dataIndex: 'company',
      title: (
        <TableHeader
          title={'Company'}
          search
          key={ChatModelSearchEnum.COMPANY_NAME}
          name={ChatModelSearchEnum.COMPANY_NAME}
          searchValue={sellsSearch?.[ChatModelSearchEnum.COMPANY_NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '12%',
      sorter: true,
      sortOrder: checkSortOrder('company', {
        field: sortField,
        order: sortOrder,
      }),
      render: (company: any) => company?.name,
    },
    {
      key: 'author',
      dataIndex: 'author',
      title: (
        <TableHeader
          title={'Author'}
          search
          key={ChatModelSearchEnum.AUTHOR}
          name={ChatModelSearchEnum.AUTHOR}
          searchValue={sellsSearch?.[ChatModelSearchEnum.AUTHOR]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '12%',
      sorter: true,
      sortOrder: checkSortOrder('author', {
        field: sortField,
        order: sortOrder,
      }),
      render: (author: any) => author?.username,
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TableHeader title={'Status'} />,
      width: '12%',
      render: (status: ModelStatusEnum) => {
        return (
          <div>
            {status === ModelStatusEnum.ACTIVE && (
              <Tag color="success">{status}</Tag>
            )}
            {status === ModelStatusEnum.CONFIRMED && (
              <Tag color="processing">{status}</Tag>
            )}
            {status === ModelStatusEnum.OUTDATE && (
              <Tag color="gold">{status}</Tag>
            )}
            {[
              ModelStatusEnum.FAILED_CREDENTIALS,
              ModelStatusEnum.INACTIVE,
              ModelStatusEnum.UNKNOWN,
            ].includes(status) && (
              <Tag color="error">
                {status === ModelStatusEnum.UNKNOWN ? 'NEW' : status}
              </Tag>
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      filters: CREATOR_STATUSES,
      filteredValue: filters?.status || [],
    },
    {
      key: 'chatters',
      dataIndex: 'chatters',
      title: (
        <TableHeader
          title={'Chatters'}
          search
          key={ChatModelSearchEnum.CHATER}
          name={ChatModelSearchEnum.CHATER}
          searchValue={sellsSearch?.[ChatModelSearchEnum.CHATER]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '15%',
      sorter: true,
      sortOrder: checkSortOrder('chatters', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, model: ChatModelType) => {
        return (
          <RowChatters
            modelId={model.id}
            modelName={model.name}
            companyName={model.company?.name}
            users={model.assignedUsers || []}
          />
        );
      },
    },
  ];

  return (
    <>
      <StoreChatModel
        maxTopPosition={150}
        onModelStored={refetch}
        publicKey={null}
        companyId={null}
      />
      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT
          }
          defPageSize={MODELS_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          setFilters={setFilterParams}
          totalCount={pageData?.filterCount}
          emptyText="No creators available."
        />
      </div>
    </>
  );
};
