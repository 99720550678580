export const LoaderUi = () => {
  return (
    <div className="fcrm-loading absolte ">
      <div className="frcm-l-frame-1"></div>
      <div className="frcm-l-frame-2"></div>
      <div className="frcm-l-frame-3"></div>
      <div className="frcm-l-frame-4"></div>
    </div>
  );
};
