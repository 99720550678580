import { useContext } from 'react';
import cn from 'classnames';
import {
  ChatModelsIcon,
  ListsIcon,
  MyProfileIcon,
  PeopleIcon,
  ProxiesIcon,
  DashboardIcon,
  ConfigIcon,
} from 'src/icons/leftMenu';
import { useNavigate, useLocation } from 'react-router-dom';
import { LeftMenuButton } from './buttons';
import { AuthContext } from './context.component';
import { RolesEnum } from 'src/types';

export type ButtonStatesType = {
  companies: boolean;
  people: boolean;
  chatModels: boolean;
  proxies: boolean;
  myProfile: boolean;
  dashboard: boolean;
};

export type ButtonLinks = {
  companies: string;
  people: string;
  chatModels: string;
  proxies: string;
  myProfile: string;
  dashboard: string;
  configuration: string;
};

export const btnLinks: ButtonLinks = {
  companies: '/companies',
  people: '/people',
  chatModels: '/chat-models',
  proxies: '/proxies',
  myProfile: '/my-profile',
  dashboard: '/dashboard',
  configuration: '/configuration'
};

export const LeftMenu = () => {
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-[72px] min-w-[72px] bg-gray-700 h-screen min-h-major-700 flex flex-col">
      <div className="pt-5 grow items-start">
        <div
          className={cn(
            {
              'fcrm-u-btn-wrap': location.pathname !== btnLinks.dashboard,
              'fcrm-u-btn-wrap-a': location.pathname === btnLinks.dashboard,
            },
            'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-dashboard',
          )}
          onClick={() => {
            navigate(btnLinks.dashboard);
          }}>
          <LeftMenuButton
            active={location.pathname === btnLinks.dashboard}
            className="small">
            <DashboardIcon
              className={cn('fcrm-u-btn-i', {
                active: location.pathname === btnLinks.dashboard,
              })}
            />
          </LeftMenuButton>
        </div>

        <div
          className={cn(
            {
              'fcrm-u-btn-wrap': location.pathname !== btnLinks.companies,
              'fcrm-u-btn-wrap-a': location.pathname === btnLinks.companies,
            },
            'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-agencies',
          )}
          onClick={() => {
            navigate(btnLinks.companies);
          }}>
          <LeftMenuButton
            active={location.pathname === btnLinks.companies}
            className="small">
            <ListsIcon
              className={cn('fcrm-u-btn-i', {
                active: location.pathname === btnLinks.companies,
              })}
            />
          </LeftMenuButton>
        </div>

        <div
          className={cn(
            {
              'fcrm-u-btn-wrap': location.pathname !== btnLinks.people,
              'fcrm-u-btn-wrap-a': location.pathname === btnLinks.people,
            },
            'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-agency-members',
          )}
          onClick={() => {
            navigate(btnLinks.people);
          }}>
          <LeftMenuButton
            active={location.pathname === btnLinks.people}
            className="small">
            <PeopleIcon
              className={cn('fcrm-u-btn-i', {
                active: location.pathname === btnLinks.people,
              })}
            />
          </LeftMenuButton>
        </div>

        <div
          className={cn(
            {
              'fcrm-u-btn-wrap': location.pathname !== btnLinks.chatModels,
              'fcrm-u-btn-wrap-a': location.pathname === btnLinks.chatModels,
            },
            'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-models',
          )}
          onClick={() => {
            navigate(btnLinks.chatModels);
          }}>
          <LeftMenuButton
            active={location.pathname === btnLinks.chatModels}
            className="small">
            <ChatModelsIcon
              className={cn('fcrm-u-btn-i', {
                active: location.pathname === btnLinks.chatModels,
              })}
            />
          </LeftMenuButton>
        </div>

        {currentUser && currentUser.role === RolesEnum.SUPER_ADMIN && (
          <>
            <div
              className={cn(
                {
                  'fcrm-u-btn-wrap': location.pathname !== btnLinks.proxies,
                  'fcrm-u-btn-wrap-a': location.pathname === btnLinks.proxies,
                },
                'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-proxies',
              )}
              onClick={() => {
                navigate(btnLinks.proxies);
              }}>
              <LeftMenuButton
                active={location.pathname === btnLinks.proxies}
                className="small">
                <ProxiesIcon
                  className={cn('fcrm-u-btn-i', {
                    active: location.pathname === btnLinks.proxies,
                  })}
                />
              </LeftMenuButton>
            </div>

            <div
              className={cn(
                {
                  'fcrm-u-btn-wrap': location.pathname !== btnLinks.configuration,
                  'fcrm-u-btn-wrap-a': location.pathname === btnLinks.configuration,
                },
                'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-configuration',
              )}
              onClick={() => {
                navigate(btnLinks.configuration);
              }}>
              <LeftMenuButton
                active={location.pathname === btnLinks.configuration}
                className="small">
                <ConfigIcon
                  className={cn('fcrm-u-btn-i', {
                    active: location.pathname === btnLinks.configuration,
                  })}
                />
              </LeftMenuButton>
            </div>
          </>
          
        )}
        
      </div>
      <div className="flex-none pb-16">
        <div
          className={cn(
            {
              'fcrm-u-btn-wrap': location.pathname !== btnLinks.myProfile,
              'fcrm-u-btn-wrap-a': location.pathname === btnLinks.myProfile,
            },
            'fcrm-menu-btn-tooltip fcrm-menu-btn-tooltip-my-profile',
          )}
          onClick={() => {
            navigate(btnLinks.myProfile);
          }}>
          <LeftMenuButton
            active={location.pathname === btnLinks.myProfile}
            className="small">
            <MyProfileIcon
              className={cn('fcrm-u-btn-i', {
                active: location.pathname === btnLinks.myProfile,
              })}
            />
          </LeftMenuButton>
        </div>
      </div>
    </div>
  );
};
