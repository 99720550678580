import { useEffect, useState } from 'react';
import { AccountsList } from './accountsList';
import { ListItemModel } from './typesListItem';
import { Button, Modal } from 'antd';

export const RowModels = ({
  modelsData,
  companyEmail,
  handleUpdateUsersData,
}: any) => {
  const [open, setOpen] = useState(false);
  const [parsedModels, setParsedModels] = useState<ListItemModel[] | null>(
    null,
  );
  const [unparsedModels, setUnparsedModels] = useState<ListItemModel[] | null>(
    null,
  );

  const filterForModelsStatus = () => {
    const parsed: ListItemModel[] = [];
    const unparsed: ListItemModel[] = [];

    modelsData.map((i: ListItemModel) => {
      const isParsed =
        i.setting?.contactsCollectingEndAt && i.setting?.vaultCollectingEndAt;
      if (isParsed) {
        parsed.push(i);
      } else {
        unparsed.push(i);
      }
    });

    if (parsed.length) setParsedModels(parsed);
    if (unparsed.length) setUnparsedModels(unparsed);
  };

  useEffect(() => {
    filterForModelsStatus();
  }, []);

  useEffect(() => {
    filterForModelsStatus();
  }, [modelsData]);

  return (
    <>
      {modelsData.length ? (
        <>
          <Button type="primary" size="small" onClick={() => setOpen(true)}>
            Open
          </Button>
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            width={'90vw'}
            styles={{
              body: {
                overflowX: 'scroll'
              }
            }}
            centered
            footer={false}>
            <div
              style={{
                maxHeight: 'calc(90vh - 50px)',
                overflow: 'auto',
                width: 'max-content',
                marginTop: 20,
                marginBottom: 10,
              }}>
              {parsedModels && (
                <AccountsList
                  title={'Parsed models'}
                  data={parsedModels}
                  companyEmail={companyEmail}
                  handleUpdateUsersData={handleUpdateUsersData}
                />
              )}

              {unparsedModels && (
                <AccountsList
                  title={'Unparsed models'}
                  data={unparsedModels}
                  companyEmail={companyEmail}
                  handleUpdateUsersData={handleUpdateUsersData}
                />
              )}
            </div>
          </Modal>
        </>
      ) : (
        'n/a'
      )}
    </>
  );
};
