import { GetPlatformsType } from 'src/components';
import { OptionType } from 'src/types';

export const getPlatformOptionsList = (
  srcPlatforms: GetPlatformsType,
): OptionType[] => {
  return srcPlatforms.Dictionary.getPlatforms.edges.map((item) => ({
    value: item.node.id,
    label: item.node.userAgent,
  }));
};
