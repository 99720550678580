import { FC, useEffect, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { UserType } from '../user';
import {
  ModelUsageStatusColorEnum,
  ModelUsageStatusEnum,
} from 'src/types/model-usage-status';

// lost (красный) 100-50%  анкет не были активны более за последние 3 дня
// not active (желтый) - 49%-31 анкет не были активны за последние 3 дня
// active (зеленый) 30% и менее акнет не были активны за последние 3 дня

interface IProps {
  row: UserType;
}

export const RowActiveStatus: FC<IProps> = ({ row }) => {
  const [status, setStatus] = useState({
    label: ModelUsageStatusEnum.NO_MODELS,
    color: ModelUsageStatusColorEnum.GRAY,
    tooltipTitle: 'Your model list is empty.',
  });

  const nonUsagePeriodDays = 3;

  const isDowntimeMoreOrEqualThreeDays = (lastUsageDate: string | null) => {
    if (!lastUsageDate) {
      return true;
    }

    const today = new Date();
    const activityDate = new Date(lastUsageDate);
    const differenceInDays = Math.floor(
      (today.getTime() - activityDate.getTime()) / (1000 * 60 * 60 * 24),
    );

    if (differenceInDays >= nonUsagePeriodDays) {
      return true;
    } else {
      return false;
    }
  };

  const getModelsUsageActivity = () => {
    const { chatModels } = row;

    if (chatModels.length > 0) {
      let modelsNotUsedLastThreeDays = 0;

      chatModels.forEach((element) => {
        if (element.setting) {
          const isTreeOrMoreDays = isDowntimeMoreOrEqualThreeDays(
            element.setting.updatedAt || null,
          );
          if (isTreeOrMoreDays) {
            modelsNotUsedLastThreeDays += 1;
          }
        }
      });

      const percentageOfNotUsageModels = Math.round(
        (modelsNotUsedLastThreeDays / chatModels.length) * 100,
      );

      if (percentageOfNotUsageModels <= 30) {
        setStatus({
          label: ModelUsageStatusEnum.ACTIVE,
          color: ModelUsageStatusColorEnum.GREEN,
          tooltipTitle:
            'Over the last 3 days you have not used less than 30% of models.',
        });
      } else if (
        percentageOfNotUsageModels > 30 &&
        percentageOfNotUsageModels < 50
      ) {
        setStatus({
          label: ModelUsageStatusEnum.NOT_ACTIVE,
          color: ModelUsageStatusColorEnum.YELLOW,
          tooltipTitle:
            'Over the last 3 days you have not used 31% - 49% of models.',
        });
      } else if (percentageOfNotUsageModels >= 50) {
        setStatus({
          label: ModelUsageStatusEnum.LOST,
          color: ModelUsageStatusColorEnum.RED,
          tooltipTitle:
            'Over the last 3 days you have not used more than 50% of the models.',
        });
      }
    }
  };

  useEffect(() => {
    getModelsUsageActivity();
  }, []);

  return (
    <div>
      <Tooltip title={status.tooltipTitle}>
        <Tag
          style={{
            color: status.color,
            borderColor: status.color,
            width: '100%',
            textAlign: 'center',
          }}>
          {status.label}
        </Tag>
      </Tooltip>
    </div>
  );
};
