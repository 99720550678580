import { Tabs } from 'src/components/tabs';
import { StatusEnum } from 'src/types';
import { AllChatModelsList } from '../all-chat-models-list.component';
import { ChatModelsInWorkList } from '../chat-models-in-work-list.component';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './style.module.css';
import PageHeader from 'src/components/base/pageHeader';

export const ChatModelsList = () => {
  const {
    handleSetSearchParams,
    setTabChangeParams,
    searchText,
    currentTab,
    canClear,
    clearAllFilters,
  } = useSearchParamsHelper();

  return (
    <div className={styles.container}>
      <PageHeader
        title="Creators"
        setSearch={handleSetSearchParams}
        search={searchText}
        clear={canClear}
        onClear={clearAllFilters}
      />

      <Tabs
        handleChange={setTabChangeParams}
        activeTab={currentTab ? currentTab : 'ALL_MODELS'}
        tabs={[
          {
            id: 'ALL_MODELS',
            title: 'All creators',
            status: StatusEnum.ACTIVE,
            content: <AllChatModelsList />,
          },
          {
            id: 'IN_WORK',
            title: 'Creators in work',
            status: StatusEnum.INACTIVE,
            content: <ChatModelsInWorkList />,
          },
        ]}
      />
    </div>
  );
};
