import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { RolesEnum } from 'src/types';
import { userDataFragment, UserType } from '../user';
import { AssignedUserType } from './types';
import { createSearchParams } from 'react-router-dom';
import { userHasOneOfRole } from 'src/helpers';
import { chatModelDataFragment } from './fragments';
import { AuthContext } from '../context.component';
import { QueryParamsKeysEnum } from 'src/types/query-params-keys.enum';
import { Button } from 'antd';
import { CloseWhiteIcon } from 'src/icons';

type UnassignModelToUserMutation = {
  unassignModel: UserType;
};

const UNASSIGN_MODEL_TO_USER_MUTATION = gql`
  mutation unassignModel($input: UnassignModelInput!) {
    unassignModel(input: $input) {
      ...ChatModelData
      assignedUsers {
        ...UserData
      }
    }
  }
  ${userDataFragment}
  ${chatModelDataFragment}
`;

export const RowChatters = ({ modelId, users }: AssignedUserType) => {
  const { currentUser } = useContext(AuthContext);

  const [unassignModel, { loading: unassignModelLoading }] =
    useMutation<UnassignModelToUserMutation>(UNASSIGN_MODEL_TO_USER_MUTATION);

  const unassign = async (targetUserId?: string): Promise<void> => {
    if (!unassignModelLoading && targetUserId) {
      await unassignModel({
        variables: {
          input: {
            userId: targetUserId,
            modelId: modelId,
          },
        },
      });
    }
  };

  const handleNavigateWithParams = (searchText: string) => {
    const params = { [QueryParamsKeysEnum.SEARCH_TEXT]: searchText };
    const searchParams = createSearchParams(params).toString();
    const newTabUrl = `/people?${searchParams}`;

    window.open(newTabUrl, '_blank');
  };

  return (
    <div>
      {!!users.length &&
        users.map((user) => (
          <div
            className="flex flex-row items-center justify-end pb-1.5 gap-[5px]"
            key={`user:${user.id}`}>
            <div
              className="grow text-right hover:cursor-pointer hover:underline"
              onClick={(e) => {
                e.preventDefault();

                handleNavigateWithParams(user.username || '');
              }}>
              {user.username}
            </div>
            {!!user.id &&
              currentUser &&
              !userHasOneOfRole(currentUser, [RolesEnum.CHATTER]) && (
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();

                    unassign(user.id);
                  }}
                  shape="circle"
                  danger
                  icon={<CloseWhiteIcon />}
                />
              )}
          </div>
        ))}
    </div>
  );
};
