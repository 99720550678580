import { SVGProps } from 'react';

export const PeopleIcon = ({
  className,
  width,
  height,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 19;
  const resHeight = height || 18;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.375 0.75C1.92525 0.75 0.75 1.92525 0.75 3.375V14.625C0.75 16.0747 1.92525 17.25 3.375 17.25H14.625C16.0747 17.25 17.25 16.0747 17.25 14.625V11.1875C17.25 10.7733 16.9142 10.4375 16.5 10.4375C16.0858 10.4375 15.75 10.7733 15.75 11.1875V14.625C15.75 15.2463 15.2463 15.75 14.625 15.75H3.375C2.75368 15.75 2.25 15.2463 2.25 14.625V3.375C2.25 2.75368 2.75368 2.25 3.375 2.25H14.625C15.2463 2.25 15.75 2.75368 15.75 3.375V6.56434L15.1553 5.96967C14.8624 5.67678 14.3876 5.67678 14.0947 5.96967C13.8018 6.26256 13.8018 6.73744 14.0947 7.03033L15.9697 8.90533C16.2626 9.19822 16.7374 9.19822 17.0303 8.90533L18.9053 7.03033C19.1982 6.73744 19.1982 6.26256 18.9053 5.96967C18.6124 5.67678 18.1376 5.67678 17.8447 5.96967L17.25 6.56434V3.375C17.25 1.92525 16.0747 0.75 14.625 0.75H3.375ZM8.99993 4.48877C7.27262 4.48877 5.87237 5.88902 5.87237 7.61633C5.87237 9.32823 7.24777 10.7189 8.95382 10.7436L8.99993 10.7434L9.04603 10.7436C10.7521 10.7189 12.1275 9.32823 12.1275 7.61633C12.1275 5.88902 10.7272 4.48877 8.99993 4.48877ZM4.37237 7.61633C4.37237 9.11273 5.08264 10.4432 6.18443 11.2891C5.85748 11.4208 5.53908 11.5757 5.23176 11.7532C4.08612 12.4147 3.1348 13.3661 2.47345 14.5119C2.26638 14.8706 2.38932 15.3293 2.74806 15.5364C3.1068 15.7434 3.56548 15.6205 3.77256 15.2618C4.30226 14.3441 5.06422 13.582 5.98182 13.0522C6.88495 12.5307 7.90753 12.2523 8.9498 12.2436L8.99993 12.2439L9.05005 12.2436C10.0923 12.2523 11.1149 12.5307 12.018 13.0522C12.9356 13.582 13.6976 14.3441 14.2273 15.2618C14.4344 15.6205 14.8931 15.7434 15.2518 15.5364C15.6105 15.3293 15.7335 14.8706 15.5264 14.5119C14.8651 13.3661 13.9137 12.4147 12.7681 11.7532C12.4608 11.5757 12.1424 11.4208 11.8154 11.2891C12.9172 10.4432 13.6275 9.11273 13.6275 7.61633C13.6275 5.0606 11.5557 2.98877 8.99993 2.98877C6.4442 2.98877 4.37237 5.0606 4.37237 7.61633Z"
      />
    </svg>
  );
};
