import {
  createRef,
  DetailedHTMLProps,
  HTMLAttributes,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { handleClickOutside } from 'src/helpers';
import cn from 'classnames';
import { ProxyType } from './types';
import { StoreProxy } from './store-proxy.component';

export const EditProxy = ({
  proxyData,
  className,
  onRefetch,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  proxyData: Partial<ProxyType>;
  onRefetch?: (proxyData: ProxyType[]) => void;
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const formRef: RefObject<HTMLDivElement> = createRef();

  const displayForm = () => {
    if (!showEditForm) {
      setShowEditForm(true);
    }
  };

  const outsideClickAction = () => {
    if (showEditForm) {
      setShowEditForm(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, formRef, outsideClickAction),
    );

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, formRef, outsideClickAction),
      );
    };
  });

  return (
    <div
      className={cn(
        'relative',
        className,
        'hover:cursor-pointer',
        'hover:underline',
      )}
      onClick={displayForm}
      {...props}>
      {proxyData.country || 'none'}
      {showEditForm && proxyData.id && (
        <StoreProxy
          proxyId={proxyData.id}
          edit
          previewOnly
          ref={formRef}
          defaultValues={{
            ...proxyData,
          }}
          onProxyStored={(data) => {
            if (onRefetch) onRefetch(data);
            setShowEditForm(false)
          }}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </div>
  );
};
