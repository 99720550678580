export enum RolesEnum {
  NONE = 'NONE',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  TEAM_LEAD = 'TEAM_LEAD',
  CHATTER = 'CHATTER',
  OWNER = 'OWNER',
}

export const USERS_ROLES = [
  {
    text: 'God (Super admin)',
    value: RolesEnum.SUPER_ADMIN,
  },
  {
    text: 'Admin',
    value: RolesEnum.ADMIN,
  },
  {
    text: 'Owner',
    value: RolesEnum.OWNER,
  },
  {
    text: 'Team Lead',
    value: RolesEnum.TEAM_LEAD,
  },
  {
    text: 'Chatter',
    value: RolesEnum.CHATTER,
  },
];

export type RolesUnion = `${RolesEnum}`;
