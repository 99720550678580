import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';

export type ButtonPropsType = {
  active: boolean;
  withStroke?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const LeftMenuButton = ({
  children,
  active,
  withStroke,
  className,
  ...props
}: ButtonPropsType) => {
  const strokeClassName = cn({
    'fcrm-lmenu-btn': withStroke,
    'fcrm-lmenu-btn-ws': !withStroke,
  });
  const resClassName = cn({
    [`${strokeClassName}-s`]: active,
    [strokeClassName]: !active,
  });

  return (
    <button className={cn(resClassName, className)} {...props}>
      {children}
    </button>
  );
};
