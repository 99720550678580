import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from 'src/components/user';

export const UnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<Login />} />
    </Routes>
  );
};
