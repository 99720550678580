import { gql } from '@apollo/client';

export const GET_APP_CONFIG = gql`
  query getAppSettings {
    getAppSettings {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_APP_CONFIG_MUTATION = gql`
  mutation updateAppSettings($settings: [UpdateAppSettingsInput!]!) {
    updateAppSettings(settings: $settings) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;
