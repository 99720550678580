import * as yup from 'yup';
import {
  BaseSyntheticEvent,
  createRef,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  RefObject,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleClickOutside, getAddjustedFormPosition } from 'src/helpers';
import { AddButton } from '../buttons';
import { Controller, useForm } from 'react-hook-form';
import {
  DropDownMenuPlacementEnum,
  RolesEnum,
  StatusEnum,
  statusesOptions,
} from 'src/types';
import { useMutation } from '@apollo/client';
import {
  CompanyType,
  StoreCompanyInputsType,
  StoreCompanyMutation,
} from './types';
import { AuthContext } from '../context.component';
import { STORE_COMPANY_MUTATION } from './queries';
import { config } from 'src/configs/config';

import styles from './store-company.module.css';

const schema = yup.object().shape({
  id: yup.string().notRequired(),
  name: yup.string().required('Agency name is required'),
  contactEmail: yup
    .string()
    .trim()
    .email('Invalid email address')
    .required('Email address is required'),
  address: yup.string().required('Address is required'),
  status: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .notRequired(),
  creatorsCount: yup.number(),
  websiteUrl: yup
    .string()
    .trim()
    .transform((_: any, originalValue: string) => {
      if (
        !originalValue.startsWith('http://') &&
        !originalValue.startsWith('https://')
      ) {
        return `https://${originalValue}`;
      }
      return originalValue;
    })
    .url('Invalid URL')
    .required('Agency website URL is required'),
  number: yup.string(),
});

export const StoreCompany = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    edit?: boolean;
    companyId?: string | null;
    defaultValues?: Partial<CompanyType> | null;
    previewOnly?: boolean;
    onCompanyStored?: (companyData: CompanyType) => void;
    onClose?: () => void;
  }
>(
  (
    { edit, onCompanyStored, companyId, defaultValues, onClose },
    ref: ForwardedRef<HTMLDivElement> | undefined,
  ) => {
    const { currentUser } = useContext(AuthContext);
    const [showAddCompany, setShowAddCompany] = useState(!!edit);
    const [dropDownMenuPlacement, setDropDownMenuPlacement] =
      useState<DropDownMenuPlacementEnum>(DropDownMenuPlacementEnum.BOTTOM);
    const {
      register,
      handleSubmit,
      reset,
      control,
      formState: { errors, isDirty },
    } = useForm<StoreCompanyInputsType>({
      resolver: yupResolver(schema),
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        status:
          defaultValues?.status && defaultValues.status === StatusEnum.INACTIVE
            ? { value: StatusEnum.INACTIVE, label: 'Inactive' }
            : { value: StatusEnum.ACTIVE, label: 'Active' },
        ...(companyId ? { id: companyId } : {}),
      },
    });

    const formRef: RefObject<HTMLDivElement> = createRef();
    const backdrop = useRef<HTMLDivElement>(null);
    const [storeCompany, { data, loading, error }] =
      useMutation<StoreCompanyMutation>(STORE_COMPANY_MUTATION);

    const displayForm = () => {
      if (!showAddCompany) {
        setShowAddCompany(true);
      }
    };
    const outsideClickAction = () => {
      if (showAddCompany) {
        setShowAddCompany(false);
      }
    };
    const send = async (
      formData: StoreCompanyInputsType,
      e?: BaseSyntheticEvent,
    ): Promise<void> => {
      e?.preventDefault();

      if (!loading) {
        await storeCompany({
          variables: {
            input: {
              ...(formData.id ? { id: formData.id } : {}),
              name: formData.name,
              contactEmail: formData.contactEmail,
              address: formData.address,
              status: formData.status.value,
              websiteUrl: formData.websiteUrl,
              creatorsCount: Number(formData.creatorsCount),
              number: formData.telegramNumber,
            },
          },
        });
      }
    };
    const addjustFormPositions = () => {
      const currRef = (ref || formRef) as RefObject<HTMLDivElement>;

      if (!currRef || !currRef.current) {
        return;
      }

      const adjustedPos = getAddjustedFormPosition(currRef.current, 100);

      if (adjustedPos.top > 0) {
        currRef.current.style.top = `-${adjustedPos.top}px`;
      }
      if (adjustedPos.dropDownMenuPlacement !== dropDownMenuPlacement) {
        setDropDownMenuPlacement(adjustedPos.dropDownMenuPlacement);
      }
    };

    useEffect(() => {
      addjustFormPositions();

      if (!edit) {
        document.addEventListener('mousedown', (e) =>
          handleClickOutside(e, formRef, outsideClickAction),
        );

        return () => {
          document.removeEventListener('mousedown', (e) =>
            handleClickOutside(e, formRef, outsideClickAction),
          );
        };
      }
    });

    useEffect(() => {
      if (!loading && data?.storeCompany && !error) {
        reset();

        if (!edit) {
          setShowAddCompany(false);
        }

        if (onCompanyStored) {
          onCompanyStored(data.storeCompany);
        }
      }
    }, [data]);

    if (currentUser && RolesEnum.CHATTER === (currentUser.role as RolesEnum)) {
      return <></>;
    }

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (backdrop?.current === e.target && onClose) {
        onClose();
      }

      setShowAddCompany(false);
    };

    return (
      <div className="relative">
        {!edit && RolesEnum.TEAM_LEAD !== (currentUser?.role as RolesEnum) && (
          <AddButton
            btnText="Add company"
            className="mb-2.5"
            onClick={displayForm}
          />
        )}
        {showAddCompany && (
          <div className={styles.dialog}>
            <div className={styles.content} ref={ref || formRef}>
              <h3 className="fcrm-h3">
                {!edit ? 'Add new company' : defaultValues?.name}
              </h3>
              {!loading && error && (
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2"></div>
                  <div className="fcrm-error-i">{error.message}</div>
                </div>
              )}
              <form className="pt-5" onSubmit={handleSubmit(send)}>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Company name:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      id="name"
                      placeholder="Company name"
                      className="fcrm-form-d-item"
                      {...register('name')}
                    />
                    {errors && errors.name && (
                      <div className="fcrm-error-i">{errors.name.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Company email:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      id="contactEmail"
                      placeholder="Enter a company email"
                      className="fcrm-form-d-item"
                      {...register('contactEmail')}
                    />
                    {errors && errors.contactEmail && (
                      <div className="fcrm-error-i">
                        {errors.contactEmail.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Company address:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      id="address"
                      placeholder="Enter a company address"
                      className="fcrm-form-d-item"
                      {...register('address')}
                    />
                    {errors && errors.address && (
                      <div className="fcrm-error-i">
                        {errors.address.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Status:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={statusesOptions}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                        />
                      )}
                    />
                    {errors && errors.status && (
                      <div className="fcrm-error-i">
                        {errors.status.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Creators count:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      type="number"
                      id="creatorsCount"
                      placeholder="Creators count"
                      className="fcrm-form-d-item"
                      {...register('creatorsCount')}
                    />
                    {errors && errors.creatorsCount && (
                      <div className="fcrm-error-i">
                        {errors.creatorsCount.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Contact number:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      id="telegramNumber"
                      placeholder="Contact number"
                      className="fcrm-form-d-item"
                      {...register('telegramNumber')}
                    />
                    {errors && errors.telegramNumber && (
                      <div className="fcrm-error-i">
                        {errors.telegramNumber.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Website Url:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      id="websiteUrl"
                      placeholder="Website Url"
                      className="fcrm-form-d-item"
                      {...register('websiteUrl')}
                    />
                    {errors && errors.websiteUrl && (
                      <div className="fcrm-error-i">
                        {errors.websiteUrl.message}
                      </div>
                    )}
                  </div>
                </div>
                {(!edit || isDirty) && (
                  <button
                    type="submit"
                    className="fcrm-submit w-28 bg-blue-500 py-2.5 my-1.5 rounded-full m-auto hover:bg-blue-600 active:bg-blue-700">
                    Save
                  </button>
                )}
              </form>
            </div>
            <div
              className={styles.backdrop}
              ref={backdrop}
              onClick={handleClose}
            />
          </div>
        )}
      </div>
    );
  },
);
