import { FC } from 'react';
import styles from './ConfirmationModal.module.css';
import Button from '../button';

interface IProps {
  confirmationText?: string;
  onConfirmAction: () => void;
  onCancelAction: () => void;
  btnConfirmTitle?: string;
  btnCancelTitle?: string;
}

const defaultConfirmationText = 'Are you sure you want to apply the changes?';

export const ConfirmationModal: FC<IProps> = ({
  confirmationText = defaultConfirmationText,
  onCancelAction,
  onConfirmAction,
  btnCancelTitle = 'CANCEL',
  btnConfirmTitle = 'CONFIRM',
}) => {
  return (
    <div className={styles.content}>
      <p className={styles.confirmationText}>{confirmationText}</p>
      <div className={styles.btnBlock}>
        <Button
          text={btnConfirmTitle}
          onClick={onConfirmAction}
          buttonType="button"
        />
        <Button
          text={btnCancelTitle}
          onClick={onCancelAction}
          buttonType="button"
          variant="secondary"
        />
      </div>
    </div>
  );
};
