import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ApolloProviderContext, App, Context } from './components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Context>
    <ApolloProviderContext>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProviderContext>
  </Context>,
);
