import { OptionType, RolesEnum, RolesUnion } from 'src/types';

export const getMyAvailableRolesOptionsList = (
  currentRole?: RolesUnion,
): OptionType[] => {
  if (!currentRole) {
    return [];
  }

  const roles = Object.values(RolesEnum);

  let resRoles: RolesEnum[] = [];

  switch (currentRole) {
    case RolesEnum.SUPER_ADMIN:
      resRoles = roles;
      break;

    case RolesEnum.ADMIN:
      resRoles = roles.filter((role) => role !== RolesEnum.SUPER_ADMIN);
      break;

    case RolesEnum.OWNER:
      resRoles = roles.filter((role) => role !== RolesEnum.SUPER_ADMIN);
      break;

    case RolesEnum.TEAM_LEAD:
      resRoles = roles.filter(
        (role) => role !== RolesEnum.SUPER_ADMIN && role !== RolesEnum.ADMIN,
      );
      break;
  }

  return resRoles.map((role) => {
    const resLabel = role.toLowerCase();

    return {
      value: role,
      label:
        role === RolesEnum.SUPER_ADMIN
          ? 'Super Admin'
          : role === RolesEnum.TEAM_LEAD
          ? 'Team Lead'
          : resLabel[0].toUpperCase() + resLabel.slice(1),
    };
  });
};

export const getRoleOptionByValue = (
  role?: RolesEnum,
): OptionType | undefined => {
  if (!role) {
    return;
  }

  switch (role) {
    case RolesEnum.SUPER_ADMIN:
      return { value: RolesEnum.SUPER_ADMIN, label: 'Super Admin' };

    case RolesEnum.ADMIN:
      return { value: RolesEnum.ADMIN, label: 'Admin' };

    case RolesEnum.OWNER:
      return { value: RolesEnum.OWNER, label: 'Owner' };

    case RolesEnum.TEAM_LEAD:
      return { value: RolesEnum.TEAM_LEAD, label: 'Team Lead' };

    case RolesEnum.CHATTER:
      return { value: RolesEnum.CHATTER, label: 'Chatter' };

    case RolesEnum.NONE:
      return { value: RolesEnum.NONE, label: 'None' };
  }
};
