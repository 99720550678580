import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import { PlusIcon } from 'src/icons';

export const AddButton = ({
  className,
  btnText,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  btnText: string;
}) => {
  return (
    <button className={cn('frcm-blue-btn', className)} {...props}>
      <PlusIcon className="my-2.5 ml-6 mr-2" />
      <div className="mr-6 text-center">{btnText}</div>
    </button>
  );
};
