import { gql } from '@apollo/client';

export const chatModelDataFragment = gql`
  fragment ChatModelData on ChatModel {
    id
    name
    email
    encPassword
    status
  }
`;

export const chatModelInWorkDataFragment = gql`
  fragment ChatModelInWorkData on ChatModelUserInWork {
    id
    startWorkAt
  }
`;
