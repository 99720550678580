import PageHeader from '../base/pageHeader';
import Table, { CustomColumnType } from '../table';
import styles from './ConfigurationPage.module.css';
import {
  GetAppSettingsFetchType,
  SettingsType,
} from './types';
import { GET_APP_CONFIG } from './queries';
import { format, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { URL_KEY } from './constants';
import { EditUrlComponent } from './editUrl';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { AddButton } from '../buttons';
import { dumpConfig } from './utils/dumpConfig';

export const ConfigPage = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [config, setConfig] = useState<SettingsType[] | null>(null);
  const [showEditUrlForm, setShowEditUrlForm] = useState(false);

  const displayForm = () => {
    if (!showEditUrlForm) {
      setShowEditUrlForm(true);
    }
  };

  const { refetch, error, loading } = useQuery(GET_APP_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (response: GetAppSettingsFetchType) => {
      dumpConfig(response, setConfig);
    },
  });

  const handleRefetch = async () => {
    const response: ApolloQueryResult<GetAppSettingsFetchType> =
      await refetch();

    if (response.data) {
      dumpConfig(response.data, setConfig);
    }
  };

  const filteredData = useMemo(() => {
    const currentSettings = config?.find((setting) => setting.key === URL_KEY);
    if (searchValue === '' && currentSettings?.value) {
      return currentSettings.value;
    } else if (
      currentSettings?.value &&
      Array.isArray(currentSettings.value)
    ) {
      return currentSettings.value.filter((url) =>
        url.key.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
  }, [searchValue, config]);

  const currentSettings = useMemo(() => {
    const foundSetting = config?.find((setting) => setting.key === URL_KEY);
    
    return foundSetting || null;
  }, [config]);

  const settingTimestamp = useMemo(() => {
    const timestamp = {
      settingCreatedAt: '',
      settingUpdateddAt : ''
    };

    if (currentSettings) {
      timestamp.settingCreatedAt = format(parseISO(currentSettings.createdAt), 'dd-MM-yyyy HH:mm');
      timestamp.settingUpdateddAt = format(parseISO(currentSettings.updatedAt), 'dd-MM-yyyy HH:mm')
    }

    return timestamp;
  }, [currentSettings]);

  const COLUMNS: CustomColumnType = [
    {
      key: 'key',
      dataIndex: 'key',
      title: 'Url name',
      width: '25%',
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: 'Value',
      width: '75%',
    },
  ];

  return (
    <div className={styles.container}>
      <PageHeader
        title="Configuration"
        setSearch={setSearchValue}
        search={searchValue}
        clear
      />

      <div className="flex justify-between items-center gap-10">
        <AddButton
          btnText="Edit url configuration"
          className="mb-2.5"
          onClick={displayForm}
        />
        <div className="flex gap-5">
          <p>
            <span className="font-semibold">Created at:</span>{' '}
            {settingTimestamp.settingCreatedAt}
          </p>
          <p>
            <span className="font-semibold">Updated at: </span>{' '}
            {settingTimestamp.settingUpdateddAt}
          </p>
        </div>
      </div>

      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}

      <div className={styles.wrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={Array.isArray(filteredData) ? filteredData : []}
          emptyText="No configuration."
        />
      </div>

      {showEditUrlForm && (
        <EditUrlComponent
          config={currentSettings}
          onUrlStored={handleRefetch}
          onClose={() => {
            setShowEditUrlForm(false);
          }}
        />
      )}
    </div>
  );
};
