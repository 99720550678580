import { useEffect, useState } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

import styles from './styles.module.css';
import { Button, Input, Space } from 'antd';

export const SearchInput = ({
  handleSearch,
  currentSearch,
  className,
  onClear,
}: {
  currentSearch: string;
  handleSearch: (str: string) => void;
  className?: string;
  onClear?: () => void;
}) => {
  const [searchStr, setSearchStr] = useState(currentSearch);

  useEffect(() => {
    setSearchStr(currentSearch);
  }, [currentSearch]);

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await handleSearch(searchStr);
    }
  };

  return (
    <div className={className ? className : ''}>
      <Space>
        <Input
          id="outlined-search"
          placeholder="Search"
          type="text"
          size="large"
          value={searchStr || ''}
          onChange={(e) => setSearchStr(e.target.value)}
          onKeyDown={handleKeyDown}
          className={styles.searchField}
        />
        <Button
          type="text"
          shape="circle"
          onClick={() => handleSearch(searchStr)}
          icon={<SearchOutlined style={{ color: '#1890ff', fontSize: 20 }} />}
        />
        {onClear && (
          <Button
            type="text"
            shape="circle"
            onClick={onClear}
            icon={
              <CloseOutlined
                style={{ color: 'rgb(211, 47, 47)', fontSize: 20 }}
              />
            }
          />
        )}
      </Space>
    </div>
  );
};
