import { useContext, FC } from 'react';
import { AuthContext, LeftMenu, TopMenu } from 'src/components';
import { SnackbarProvider } from 'notistack';
import { createStyles } from 'antd-style';
import { ConfigProvider } from 'antd';

const useStyle = createStyles(({ prefixCls, css }) => ({
  customButton: css`
    &.${prefixCls}-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
}));

interface IProps {
  children: React.ReactNode;
}

export const Layout: FC<IProps> = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { styles } = useStyle();

  return (
    <SnackbarProvider maxSnack={5}>
      <ConfigProvider
        button={{
          className: styles.customButton,
        }}>
        <div>
          {currentUser && <TopMenu />}
          <div className="w-full m-auto h-screen min-h-major-960 flex flex-row items-start">
            {currentUser && <LeftMenu />}
            {children}
          </div>
        </div>
      </ConfigProvider>
    </SnackbarProvider>
  );
};
