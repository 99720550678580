import { OptionType } from './option.type';

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
}

export type UserStatusUnion = `${UserStatusEnum}`;

export const userStatusesArr = Object.values(UserStatusEnum);

export const userStatusesOptions: OptionType[] = userStatusesArr.map(
  (status) => {
    let resLabel = status.toLowerCase();

    if (resLabel.indexOf('_') !== -1) {
      const tmpArr = resLabel.split('_');

      resLabel = `${tmpArr[0]} ${tmpArr[1]}`;
    }

    return {
      value: status,
      label: resLabel[0].toUpperCase() + resLabel.slice(1),
    };
  },
);

export const USERS_STATUSES = [
  {
    text: 'Active',
    value: UserStatusEnum.ACTIVE,
  },
  {
    text: 'Inactive',
    value: UserStatusEnum.INACTIVE,
  },
  {
    text: 'Not confirmed',
    value: UserStatusEnum.NOT_CONFIRMED,
  },
];
