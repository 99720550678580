export enum QueryParamsKeysEnum {
  FILTERS = 'filters',
  SELLS_SEARCH = 'search',
  SEARCH_TEXT = 'search_text',
  PAGE = 'page',
  PAGINATION_LIMIT = 'pagination_limit',
  SORTING_FIELD = 'field',
  SORTING_ORDER = 'order',
  CURRENT_TAB = 'current_tab',
}
