import { oneDayMilliseconds } from '../constants/time-range';

export const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(
    Date.now() + days * oneDayMilliseconds,
  ).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value,
  )}; expires=${expires}; path=/; Secure; SameSite=Strict`;
};
