import { GET_CHAT_MODELS_IN_WORK_BY_PAGES_QUERY } from './queries';
import { ChatModelInWorkType, GetChatModelsInWorkByPagesType } from './types';
import Table from '../table';
import { dumpModelsInWork } from 'src/helpers/dump.helper';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './Models.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import TableHeader from '../base/tableHeader';
import { ModelInWorkSearchEnum } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import { dumpCreatorInWorkSortParams } from 'src/utils/dump-sort-params.utils';

const MODELS_PAGINATION_LIMIT = 10;

export const ChatModelsInWorkList = () => {
  const {
    setPageParams,
    setPaginationLimitParams,
    setSortParams,
    sellsSearch,
    setSellsSearchParams,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, loading, error, pageData } = useQueryHook<
    GetChatModelsInWorkByPagesType,
    ChatModelInWorkType
  >({
    query: GET_CHAT_MODELS_IN_WORK_BY_PAGES_QUERY,
    variables: {
      limit: paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...prepareSearchBySells(sellsSearch),
      ...dumpCreatorInWorkSortParams({ field: sortField, order: sortOrder }),
    },
    dumpFunction: dumpModelsInWork,
  });

  const COLUMNS = [
    {
      key: 'name',
      dataIndex: 'name',
      title: (
        <TableHeader
          title={'Creator name'}
          search
          key={ModelInWorkSearchEnum.NAME}
          name={ModelInWorkSearchEnum.NAME}
          searchValue={sellsSearch?.[ModelInWorkSearchEnum.NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '33%',
      sorter: true,
      sortOrder: checkSortOrder('name', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'startWorkAt',
      dataIndex: 'startWorkAt',
      title: 'Work started at',
      width: '33%',
      sorter: true,
      sortOrder: checkSortOrder('startWorkAt', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'chatter',
      dataIndex: 'chatter',
      title: (
        <TableHeader
          title={'Chatter in work'}
          search
          key={ModelInWorkSearchEnum.CHATER}
          name={ModelInWorkSearchEnum.CHATER}
          searchValue={sellsSearch?.[ModelInWorkSearchEnum.CHATER]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '33%',
      sorter: true,
      sortOrder: checkSortOrder('chatter', {
        field: sortField,
        order: sortOrder,
      }),
    },
  ];

  return (
    <div className={styles.tableWrapper}>
      <Table
        loading={loading}
        columns={COLUMNS}
        data={data}
        setSort={setSortParams}
        currentPage={currentPage}
        setPage={setPageParams}
        pageLimit={paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT}
        defPageSize={MODELS_PAGINATION_LIMIT}
        setPaginationLimit={setPaginationLimitParams}
        totalCount={pageData?.totalCount}
        emptyText="No models available."
      />

      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}
    </div>
  );
};
