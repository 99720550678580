import { ProxyType } from 'src/components';
import { OptionType } from 'src/types';

export const getProxyOptionsList = (
  srcProxies: ProxyType[]
): OptionType[] => {
  return srcProxies.map((item) => ({
    value: item.id,
    label: `${item.country} ${item.host}`,
  }));
};
