import { config } from 'src/configs/config';
import { DropDownMenuPlacementEnum } from 'src/types';

export type AdjustedFormPositionType = {
  top: number;
  right?: number;
  dropDownMenuPlacement: DropDownMenuPlacementEnum;
};

export const getAddjustedFormPosition = (
  element: HTMLDivElement,
  dropDownMenuMaxHeight?: number,
): AdjustedFormPositionType => {
  const wndHeight = window.innerHeight;
  const wndWidth = window.innerWidth;
  const boundaryRect = element.getBoundingClientRect();

  const top = boundaryRect.bottom - wndHeight;
  const right = boundaryRect.left + boundaryRect.width + 10;
  const resDropDownMenuMaxHeight =
    dropDownMenuMaxHeight || config.maxDropDownMenuHeight;

  return {
    top: top > 0 ? top + 120 : 0,
    right: wndWidth < right ? 0 : undefined,
    dropDownMenuPlacement:
      top > 0 || (top <= 0 && Math.abs(top) < resDropDownMenuMaxHeight + 10)
        ? DropDownMenuPlacementEnum.TOP
        : DropDownMenuPlacementEnum.BOTTOM,
  };
};
