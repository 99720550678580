import { ProxyStatusEnum } from 'src/types';
import { GetProxiesByPagesType, ProxyType } from './types';
import { GET_PROXIES_BY_PAGES_QUERY } from './queries';
import Table from '../table';
import { dumpProxies } from 'src/helpers/dump.helper';
import { EditProxy } from './edit-proxy.component';
import { StoreProxy } from './store-proxy.component';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './Proxy.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { Tag } from 'antd';
import { dumpProxySortParams } from 'src/utils/dump-sort-params.utils';

const PROXIES_PAGINATION_LIMIT = 10;

interface IProps {
  proxyStatus: ProxyStatusEnum;
  isAvailable?: boolean;
}

export const ProxyTab: React.FC<IProps> = ({
  proxyStatus,
  isAvailable = false,
}) => {
  const {
    setPageParams,
    setPaginationLimitParams,
    setSortParams,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, pageData, loading, refetch, error } = useQueryHook<
    GetProxiesByPagesType,
    ProxyType
  >({
    query: GET_PROXIES_BY_PAGES_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      available: isAvailable,
      status: proxyStatus,
      limit: paginationLimit ? paginationLimit : PROXIES_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...dumpProxySortParams({ field: sortField, order: sortOrder }),
    },
    dumpFunction: dumpProxies,
  });

  const COLUMNS = [
    {
      key: 'country',
      dataIndex: 'country',
      title: 'Country',
      width: '10%',
      sorter: true,
      sortOrder: checkSortOrder('country', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, proxy: ProxyType) => {
        return (
          <EditProxy
            className="fcrm-greed-col"
            proxyData={{ ...proxy }}
            onRefetch={refetch}
          />
        );
      },
    },
    {
      key: 'company',
      dataIndex: 'company',
      title: 'Company name',
      width: '15%',
      render: (__: any, proxy: ProxyType) => {
        return <p>{proxy.company?.name}</p>;
      },
    },
    {
      key: 'models',
      dataIndex: 'models',
      title: 'Models',
      width: '25%',
      render: (__: any, proxy: ProxyType) => {
        return (
          <>
            <p>Model(s) count: {proxy.assignedModels.length}</p>
            {proxy.assignedModels.map((model) => {
              return <p key={model.name}>{model.name}</p>;
            })}
          </>
        );
      },
    },
    {
      key: 'host',
      dataIndex: 'host',
      title: 'Host',
      width: '20%',
      sorter: true,
      sortOrder: checkSortOrder('host', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'port',
      dataIndex: 'port',
      title: 'Port',
      width: '15%',
      sorter: true,
      sortOrder: checkSortOrder('port', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: '15%',
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      render: (status: ProxyStatusEnum) => {
        return (
          <div>
            {status === ProxyStatusEnum.ACTIVE && (
              <Tag color="success">{status}</Tag>
            )}
            {status === ProxyStatusEnum.AVAILABLE && (
              <Tag color="processing">{status}</Tag>
            )}
            {status === ProxyStatusEnum.DISCONNECTED && (
              <Tag color="warning">{status}</Tag>
            )}
            {status === ProxyStatusEnum.INACTIVE && (
              <Tag color="error">{status}</Tag>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <StoreProxy onProxyStored={refetch} />
      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : PROXIES_PAGINATION_LIMIT
          }
          defPageSize={PROXIES_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          totalCount={pageData?.totalCount}
          emptyText="No proxies available."
        />

        {!loading && error && (
          <div className="fcrm-error-i">
            {error?.message || 'Internal error'}
          </div>
        )}
      </div>
    </>
  );
};
