import { gql } from '@apollo/client';
import { companyDataFragmentUser, userDataFragment } from './fragments';

export const GET_USERS_QUERY = gql`
  query getUsers(
    $before: ID
    $after: ID
    $first: Int
    $last: Int
    $status: StatusEnum
  ) {
    UserGroup {
      getUsers(
        before: $before
        after: $after
        first: $first
        last: $last
        status: $status
      ) {
        edges {
          node {
            ...UserData
            company {
              id
              name
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${userDataFragment}
`;

export const GET_USERS_BY_PAGES_QUERY = gql`
  query getUsersByPages(
    $page: Int!
    $limit: Int!
    $name: String
    $email: String
    $status: [StatusEnum!]
    $roles: [RolesEnum!]
    $sorting: SortingInput
    $searchStr: String
    $searchBy: [SearchByUserInput!]
  ) {
    UserGroup {
      getUsersByPages(
        page: $page
        limit: $limit
        name: $name
        email: $email
        status: $status
        roles: $roles
        sorting: $sorting
        searchStr: $searchStr
        searchBy: $searchBy
      ) {
        nodes {
          id
          username
          email
          lastLoginAt
          emailConfirmedAt
          isEmailConfirmed
          createdAt
          revenue
          status
          role
          autologinKey
          chatModels {
            id
            name
            email
            confirmedAt
            createdAt
            status
            canEarn
            campaignsStats {
              totalSubscribers
              campaignCount
            }
            setting {
              totalContactsToCollecting
              contactsCollected
              totalTransactionsToCollecting
              transactionsCollected
              totalVaultToCollecting
              vaultCollected
              contactsCollectingStartAt
              transactionsCollectingStartAt
              vaultCollectingStartAt
              transactionsCollectingEndAt
              contactsCollectingEndAt
              vaultCollectingEndAt
              updatedAt
              lastOnlineAt
              accessProvidedAt
              confirmationEmailSentAt
            }
          }
          company {
            id
            name
            status
            contactEmail
          }
        }
        pageInfo {
          pageNumber
          totalPages
          totalCount
        }
      }
    }
  }
`;

export const STORE_USER_MUTATION = gql`
  mutation storeUser($input: StoreUserInput!) {
    storeUser(input: $input) {
      ...UserData
    }
  }
  ${userDataFragment}
`;

export const ADD_USER_TO_COMPANY_MUTATION = gql`
mutation addUserToCompany($input: AddUserToCompanyInput!) {
  addUserToCompany(input: $input) {
    ...UserData
    company {
      ...CompanyData
    }
  }
}
${userDataFragment}
${companyDataFragmentUser}
`;
