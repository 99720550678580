import { UserType } from 'src/components';
import { OptionType } from 'src/types';

// export const getUserOptionsList = (
//   srcUsers: Partial<UserType>[],
// ): OptionType[] => {
//   return srcUsers.map((item) => ({
//     value: item?.id || '',
//     label: `${item?.username || 'No name'}${
//       item.company && item.company.length ? ` - ${item.company[0].name}` : ''
//     }`,
//   }));
// };


export const getUserOptionsList = (
  srcUsers: Partial<UserType>[],
): OptionType[] => {
  const options = srcUsers.map((item) => {
    if (item?.id ) {
      return {
        value: item?.id || '',
        label: `${item?.username || 'No name'}${
          item.company && item.company.length ? ` - ${item.company[0].name}` : ''
        }`,
      }
    }

    return (
      {
        value:'',
        label: ''
      }
    )
  })

  const notEmptyOptions = options.filter(opt => opt.value !== '');

  return notEmptyOptions;
};
