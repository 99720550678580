import { SVGProps } from 'react';

export const DropDownIcon = ({
  width,
  height,
  className,
  onClick,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 20;
  const resHeight = height || 20;

  return (
    <svg
      width={resWidth}
      height={resHeight}
      viewBox={`0 0 ${resWidth} ${resHeight}`}
      className={className}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 7.5L10 13.75L3.75 7.5"
        stroke="#717D8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
