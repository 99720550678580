import { ConfigType } from 'src/types';

const isDev = process.env.NODE_ENV === 'development';

export const config: ConfigType = {
  isDev,
  graphQlUrl: isDev ? 'http://localhost:4002/graphql' : '/graphql',
  maxDropDownMenuHeight: 200, // px
  dateFormat: { day: '2-digit', month: '2-digit', year: '2-digit' }
};
