import { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import { AuthContext } from '../context.component';
import { DropDownMenu } from './drop-down-menu.component';

export const UserMenu = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="flex flex-row items-center whitespace-nowrap" {...props}>
      <span className={'text-white'}>{`Welcome ${
        currentUser?.username || ''
      }`}</span>
      <DropDownMenu />
    </div>
  );
};
