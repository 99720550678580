import {
  createRef,
  DetailedHTMLProps,
  HTMLAttributes,
  RefObject,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { CompanyType } from './types';
import { StoreCompany } from './store-company.component';
import { handleClickOutside } from 'src/helpers';

export const EditCompany = ({
  companyData,
  className,
  updateUserInList,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  companyData: Partial<CompanyType>;
  updateUserInList: (data: any) => void;
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const formRef: RefObject<HTMLDivElement> = createRef();

  const displayForm = () => {
    if (!showEditForm) {
      setShowEditForm(true);
    }
  };

  const outsideClickAction = () => {
    if (showEditForm) {
      setShowEditForm(false);
    }
  };

  const onCompanyStored = (company: CompanyType) => {
    updateUserInList((prev: CompanyType[]) =>
      prev.map((stored) => {
        if (stored.id === company.id) {
          console.log(stored, company);
          return { ...stored, ...company };
        }

        return stored;
      }),
    );

    setShowEditForm(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) =>
      handleClickOutside(e, formRef, outsideClickAction),
    );

    return () => {
      document.removeEventListener('mousedown', (e) =>
        handleClickOutside(e, formRef, outsideClickAction),
      );
    };
  });

  return (
    <div
      className={cn(
        'relative',
        className,
        'hover:cursor-pointer',
        'hover:underline',
      )}
      onClick={displayForm}
      {...props}>
      <p
        style={{
          width: '250px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {companyData.name || ''}
      </p>
      {showEditForm && companyData.id && (
        <StoreCompany
          companyId={companyData.id}
          edit
          previewOnly
          ref={formRef}
          defaultValues={{
            ...companyData,
          }}
          onCompanyStored={onCompanyStored}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </div>
  );
};
