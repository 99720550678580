import { SVGProps } from 'react';

export const SmallLogoIcon = ({
  width,
  height,
  className,
}: SVGProps<SVGSVGElement>) => {
  const resWidth = width || 160;
  const resHeight = height || 40;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={resWidth}
      height={resHeight}
      className={className}
      viewBox="0 0 414 80"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3521 19.9257C43.9054 12.1871 28.9314 8.39727 20.4067 7.84639C10.8834 7.23098 3.81758 3.9488 1.05273 2C2.28156 10.308 9.96177 26.9241 22.5572 33.3859C22.5572 33.3859 18.7826 32.5134 16.4066 31.5397C15.0038 30.9648 13.1979 30.024 12.0495 29.4043C11.5946 29.1588 11.0552 29.5411 11.1665 30.0466C13.459 40.4632 23.1455 54.5648 40.8919 56.0789C42.5791 60.5165 45.2327 64.6383 48.7881 68.1236C58.6173 77.7652 73.3966 80.6488 86.2397 75.4305C95.2191 71.7865 102.012 64.6816 105.287 56.0784C123.03 54.562 132.715 40.4622 135.007 30.0466C135.118 29.5411 134.579 29.1588 134.124 29.4043C132.976 30.024 131.17 30.9648 129.767 31.5397C127.391 32.5134 123.616 33.3859 123.616 33.3859C136.212 26.9241 143.892 10.308 145.121 2C142.356 3.9488 135.29 7.23098 125.767 7.84639C117.244 8.39716 102.274 12.1855 96.8247 19.9208C93.758 17.0463 90.1707 14.7499 86.2461 13.158C82.0769 11.4606 77.6044 10.5898 73.0932 10.5898L73.0868 10.5962C64.068 10.5962 55.6229 14.0545 49.3521 19.9257Z"
        fill="#00AEEF"
      />
      <rect x="274" y="38" width="14" height="8" rx="4" fill="#00AEEF" />
      <path
        d="M389.77 46.462C392.012 37.1299 394.276 27.8049 396.468 18.4588C396.735 17.3288 397.193 16.7656 398.236 16.5473C401.774 15.8151 405.293 15.7835 408.818 16.66C409.651 16.8677 410 17.181 410 18.1561C409.974 32.3601 409.993 46.5641 409.934 60.7681C409.934 61.3102 409.338 62.141 408.848 62.3381C406.692 63.2041 404.477 63.1724 402.242 62.5212C401.119 62.1938 400.675 61.6481 400.715 60.3245C400.994 50.7109 401.195 41.0902 401.416 31.473C401.429 30.9168 401.416 30.3606 401.142 29.7692C399.99 34.3138 398.838 38.8584 397.69 43.4029C396.518 48.032 395.386 52.6752 394.141 57.2831C393.964 57.9343 393.292 58.8109 392.749 58.8989C390.889 59.2016 388.98 59.3354 387.097 59.2755C385.718 59.2298 385.017 58.2265 384.645 56.7163C382.466 47.8384 380.192 38.9851 377.944 30.1247C377.861 29.8044 377.707 29.5017 377.453 28.8575C377.693 33.8597 378.003 38.4747 378.118 43.0967C378.266 49.0212 378.279 54.9492 378.276 60.8737C378.276 61.3384 377.782 62.0424 377.361 62.2255C374.935 63.2745 372.466 63.2006 369.978 62.3451C368.885 61.9685 369.004 61.1131 369.004 60.2365C369.004 47.1484 369.004 34.0568 369.004 20.9687C369.004 16.9029 368.981 16.667 372.782 16.2516C375.409 15.963 378.108 16.4276 380.774 16.5685C381.87 16.6248 382.335 17.3605 382.591 18.4306C384.227 25.2281 385.889 32.0186 387.555 38.8091C388.187 41.3823 388.842 43.9451 389.487 46.5148C389.583 46.4972 389.675 46.4761 389.77 46.4585V46.462Z"
        fill="#00AEEF"
      />
      <path
        d="M340.702 44.5225C340.702 46.5357 340.702 48.4745 340.702 50.4133C340.702 53.309 340.702 56.2048 340.702 59.1006C340.702 62.2374 340.586 62.5634 337.571 62.7938C335.51 62.9498 333.403 62.5953 331.331 62.3579C330.502 62.2622 330 61.766 330 60.6778C330.041 46.082 330.031 31.4862 330.034 16.8904C330.034 16.6636 330.089 16.4403 330.147 16.0079C331.133 16.0079 332.082 15.9902 333.028 16.0079C338.592 16.139 344.167 16.0965 349.714 16.5076C352.021 16.6778 354.373 17.571 356.51 18.5811C361.535 20.9594 363.604 25.3722 363.389 30.9227C363.181 36.2889 360.893 40.4039 356.043 42.7184C355.688 42.8885 355.336 43.0658 354.763 43.3493C358.183 49.4421 361.569 55.4676 365 61.5816C361.344 63.4318 357.825 63.3999 354.305 61.9042C353.749 61.6667 353.267 61.011 352.953 60.4333C350.519 55.9709 348.137 51.473 345.733 46.9929C344.73 45.1215 343.053 44.2968 340.702 44.519V44.5225ZM340.842 23.6106V37.6145C343.644 37.2884 346.338 37.2671 348.877 36.5972C351.564 35.8884 352.796 33.3258 352.649 30.033C352.513 26.8962 351.165 24.8263 348.352 24.2698C345.935 23.7914 343.426 23.8162 340.842 23.6106Z"
        fill="#00AEEF"
      />
      <path
        d="M193.003 58.664C190.657 61.755 187.718 63.5619 184.114 63.9212C174.297 64.8993 170.443 56.5903 172.566 49.142C173.859 44.6069 177.374 42.4799 181.412 41.6333C184.443 41.0002 187.58 40.9326 190.706 40.6125C191.828 33.872 187.764 32.9472 182.979 33.5199C181.208 33.7333 179.441 34.2562 177.749 34.8751C176.808 35.2201 176.288 34.9107 175.794 34.2384C174.517 32.4919 174.102 30.5392 174.803 28.4086C174.932 28.0173 175.284 27.5691 175.63 27.4304C181.001 25.2464 186.517 24.1687 192.177 25.7586C197.904 27.3664 200.563 31.2612 200.635 37.806C200.691 42.9636 200.649 48.1212 200.649 53.2788C200.649 54.7265 200.724 56.0283 202.159 56.9033C203.242 57.5649 203.235 60.1828 202.39 62.0253C201.758 63.4019 200.988 64.2306 199.309 63.8643C196.426 63.2382 194.178 61.8902 192.997 58.6604L193.003 58.664ZM190.864 46.3783C187.596 46.0866 185.141 46.5597 183.67 47.6979C182.027 48.9713 181.412 51.5074 182.192 53.7874C182.827 55.6406 184.193 56.416 186.457 56.2133C188.35 56.0461 190.709 54.1716 190.828 52.4891C190.969 50.4972 190.861 48.484 190.861 46.3783H190.864Z"
        fill="#008CCF"
      />
      <path
        d="M313.119 15.0081C317.306 14.9589 321.217 15.9826 324.641 18.5577C325.182 18.9622 325.716 20.0739 325.562 20.6614C325.165 22.1846 324.624 23.8028 323.697 25.006C322.37 26.7262 321.536 26.6066 319.672 25.5196C314.406 22.4414 307.266 22.8917 305.021 31.4753C303.633 36.7768 303.613 42.1451 305.024 47.436C306.957 54.6793 312.827 57.1383 319.389 53.6134C320.505 53.0153 321.452 51.7278 322.998 52.7796C324.718 53.9546 325.636 55.5693 325.952 57.6449C326.147 58.943 325.76 59.8119 324.645 60.5506C314.698 67.1467 297.014 65.2294 293.657 46.458C292.683 41.0194 292.753 35.6124 294.131 30.2687C296.503 21.0941 303.24 14.7408 313.119 15.0081Z"
        fill="#00AEEF"
      />
      <path
        d="M218.782 30.4686C220.961 27.6157 223.515 25.7102 226.85 25.1446C231.825 24.2997 236.136 27.2127 237.409 32.4236C237.741 33.7775 237.937 35.2058 237.943 36.6022C237.993 44.3195 237.933 52.0368 238 59.7541C238.01 61.0868 237.595 61.861 236.429 61.9141C234.144 62.0201 231.848 62.0201 229.563 61.9494C228.182 61.907 227.843 60.9702 227.853 59.5526C227.912 52.3655 227.906 45.1785 227.863 37.9915C227.843 35.0149 226.684 33.4664 224.594 33.3321C222.083 33.1695 219.047 36.2557 219.041 39.0202C219.028 45.8537 218.988 52.6908 219.071 59.5208C219.091 61.0727 218.636 61.9353 217.221 61.9706C214.932 62.0272 212.64 62.0025 210.355 61.8929C209.422 61.8469 208.997 61.2 209 60.0864C209.037 49.5409 209.007 38.9955 209.07 28.45C209.07 27.9021 209.638 27.1208 210.133 26.8663C210.9 26.4668 211.823 26.3183 212.693 26.2511C216.46 25.9542 217.367 26.5799 218.782 30.4686Z"
        fill="#008CCF"
      />
      <path
        d="M146.221 16.0325C150.206 16.0325 154.155 16.0325 158.101 16.0325C162 16.0325 165.899 16.0851 169.795 16.0009C170.921 15.9764 171.455 16.4533 171.666 17.4704C171.843 18.3226 172.003 19.1994 172 20.0657C171.983 23.443 171.358 24.0743 168.211 24.0743C164.372 24.0743 160.534 24.0743 156.561 24.0743V36.0687C159.648 36.0687 162.745 36.0687 165.839 36.0687C168.916 36.0687 169.745 36.9876 169.588 40.2668C169.561 40.8489 169.471 41.4311 169.367 42.0028C169.137 43.2969 168.572 43.9598 167.125 43.9072C163.634 43.7809 160.139 43.8651 156.421 43.8651C156.421 44.6717 156.421 45.2925 156.421 45.9168C156.421 50.5953 156.371 55.2773 156.454 59.9523C156.478 61.2289 156.006 61.9268 154.931 61.9584C152.408 62.032 149.879 62.0075 147.356 61.8882C146.605 61.8532 145.997 61.4183 146 60.2925C146.047 45.7274 146.04 31.1657 146.053 16.6006C146.053 16.4919 146.114 16.3867 146.221 16.0325Z"
        fill="#008CCF"
      />
      <path
        d="M256.87 64C251.699 63.8223 248.172 62.9803 244.846 61.083C243.318 60.2125 242.599 59.0862 243.227 57.2777C243.603 56.2011 244.063 55.1033 244.713 54.215C245.491 53.1562 246.375 52.8116 247.748 53.6678C250.649 55.4799 253.817 56.3717 257.207 55.4728C258.622 55.0997 259.73 54.35 259.856 52.5877C259.989 50.7402 258.966 49.7311 257.534 49.1946C255.251 48.3383 252.907 47.6668 250.64 46.7572C247.835 45.6309 245.41 43.9006 244.234 40.6851C241.993 34.5598 245.131 28.0222 251.284 25.9579C256.55 24.1885 261.598 24.9453 266.462 27.6101C268.37 28.6547 268.746 29.3546 268.014 31.5255C267.609 32.7229 266.751 33.8207 265.876 34.7019C265.494 35.0856 264.458 35.0572 263.894 34.7836C261.589 33.6573 259.231 32.9858 256.699 33.1776C254.775 33.3233 253.535 34.3324 253.334 35.8673C253.149 37.2956 254.03 38.628 255.85 39.3243C258.279 40.2517 260.769 40.9907 263.201 41.9109C267.382 43.492 269.937 46.608 269.999 51.5893C270.06 56.5387 267.78 60.145 263.7 61.9961C261.113 63.1721 258.247 63.6092 256.867 63.9964L256.87 64Z"
        fill="#008CCF"
      />
    </svg>
  );
};
