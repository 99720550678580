import { gql } from '@apollo/client';
import { userDataFragment } from '../user';
import { companyDataFragment } from './fragments';

export const GET_COMPANIES_QUERY = gql`
  query getCompanies($before: ID, $after: ID, $first: Int, $last: Int) {
    AgencyGroup {
      getCompanies(
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            ...CompanyData
            author {
              ...UserData
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        total
      }
    }
  }
  ${companyDataFragment}
  ${userDataFragment}
`;

export const STORE_COMPANY_MUTATION = gql`
  mutation storeCompany($input: StoreCompanyInput!) {
    storeCompany(input: $input) {
      ...CompanyData
    }
  }
  ${companyDataFragment}
`;

export const GET_COMPANIES_BY_PAGES_QUERY = gql`
  query getCompaniesByPages(
    $page: Int!
    $limit: Int!
    $searchStr: String
    $searchBy: [CompanySearchBy!]
    $sorting: CompanySortingInput
  ) {
    AgencyGroup {
      getCompaniesByPages(
        page: $page
        limit: $limit
        searchStr: $searchStr
        searchBy: $searchBy
        sorting: $sorting
      ) {
        nodes {
          id
          name
          country
          contactEmail
          address
          status
          websiteUrl
          creatorsCount
          telegramNumber
          createdAt
          updatedAt
          employeesCount
          author {
            ...UserData
          }
        }
        pageInfo {
          pageNumber
          totalPages
          totalCount
        }
      }
    }
  }
  ${userDataFragment}
`;
