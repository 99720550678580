import { gql } from '@apollo/client';

export const platformDataFragment = gql`
  fragment PlatformData on Platform {
    id
    platform
    userAgent
    secChUa
    secChUaFullVersionList
    secChUaPlatform
    secChUaModel
    secChUaPlatformVersion
    secChUaMobile
  }
`;
