import { gql } from '@apollo/client';

export const userDataFragment = gql`
  fragment UserData on User {
    id
    username
    email
    status
    role
    autologinKey
    jwt
    refreshToken
  }
`;

export const companyDataFragmentUser = gql`
  fragment CompanyData on Company {
    id
    name
    contactEmail
    address
    status
    websiteUrl
    creatorsCount
    telegramNumber
  }
`;
