import { config } from 'src/configs/config';

export const getStringDate = (
  date: string | null | undefined,
  withTime?: boolean,
) => {
  if (!date) return;

  const dateString = new Date(date).toLocaleString('ua', config.dateFormat);

  if (!withTime) return dateString;

  const timeString = new Date(date).toLocaleTimeString();

  return `${dateString} ${timeString}`;
};

const combyneDateString = (time: number) => {
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  let interval = '';

  if (days > 0) {
    interval += `${days} days `;
  }
  interval += `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return interval;
};

export function formatDateInterval(
  startDate: string | undefined | null,
  endDate: string | undefined | null,
) {
  if (!startDate || !endDate) return;
  const milliseconds = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime(),
  );

  return combyneDateString(milliseconds);
}

const makeDates = (args: [string]): Date[] => {
  return args.map((date) => new Date(date));
};

export const getTotalTime = (starts: [string], ends: [string]) => {
  if (!starts) return null;
  const makeStartDate = makeDates(starts);
  const makeEndDate = makeDates(ends);

  if (
    !makeStartDate ||
    !makeStartDate.length ||
    !makeEndDate ||
    !makeEndDate.length
  )
    return null;

  const startDate = Math.min(...makeStartDate.map((date) => date.getTime()));
  const endDate = Math.max(...makeEndDate.map((date) => date.getTime()));

  const total = endDate - startDate;

  return combyneDateString(total);
};
