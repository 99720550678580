export const DashboardIcon = ({ className }: { className: any }) => {
  return (
    <svg
      className={className}
      focusable="false"
      aria-hidden="true"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-testid="DashboardIcon">
      <path d="M3 13h8V3H3zm0 8h8v-6H3zm10 0h8V11h-8zm0-18v6h8V3z"></path>
    </svg>
  );
};
