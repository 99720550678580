import { RefObject } from 'react';

export const handleClickOutside = (
  e: MouseEvent,
  ref: RefObject<HTMLDivElement>,
  action: () => void,
) => {
  if (ref.current) {
    const cur = ref.current;

    if (cur && !cur.contains(e.target as Node)) {
      action();
    }
  }
};
