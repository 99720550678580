import { Tag } from 'antd';
import { geUserActivity, ModelActivityEnum } from '../../helpers';
import { ListItemModel } from './typesListItem';

export const RowActivity = ({ userData }: any) => {
  const status = geUserActivity(
    userData.chatModels.length || 0,
    userData.chatModels.map(
      (item: ListItemModel) => item.setting?.lastOnlineAt,
    ),
  );

  return (
    <>
      {userData.chatModels.length ? (
        status === ModelActivityEnum.ACTIVE ? (
          <Tag color="success">ACTIVE</Tag>
        ) : status === ModelActivityEnum.NOT_ACTIVE ? (
          <Tag color="warning">NOT ACTIVE</Tag>
        ) : status === ModelActivityEnum.LOST ? (
          <Tag color="error">LOST</Tag>
        ) : (
          ''
        )
      ) : (
        'n/a'
      )}
    </>
  );
};
