import { SearchInput } from 'src/components/dashboard/searchInput';

import styles from './style.module.css';
interface PageHeaderProps {
  title: string;
  search: string;
  setSearch: (value: string) => void;
  clear?: boolean;
  onClear?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  search,
  setSearch,
  clear,
  onClear,
}) => {
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{title}</h4>
      <SearchInput
        handleSearch={setSearch}
        currentSearch={search}
        onClear={clear ? onClear || setSearch.bind(this, '') : undefined}
      />
    </div>
  );
};

export default PageHeader;
