import {
  createContext,
  DetailedHTMLProps,
  HTMLAttributes,
  useMemo,
  useState,
} from 'react';
import { ContextType } from 'src/types/context.type';
import { UserType } from './user/types';
import { setCookie } from 'src/helpers';
import { ACCESS_TOKEN, REFRESH_TOKEN_FANS_CRM } from 'src/constants/token-name';

export const AuthContext = createContext<ContextType>({
  currentUser: null,
  setCurrentUser: () => {},
});

const CURRENT_USER_STORAGE_KEY = 'currentUser';

const currentUserFromStorage = localStorage.getItem(
  CURRENT_USER_STORAGE_KEY,
);

export const Context = ({
  children,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(
    currentUserFromStorage ? JSON.parse(currentUserFromStorage) : null,
  );

  const updateCurrentUserData = (user: UserType | null) => {
    if (!user) {
      localStorage.removeItem(CURRENT_USER_STORAGE_KEY);
      localStorage.removeItem(ACCESS_TOKEN);
    } else {

      if (user.refreshToken) {
        setCookie(REFRESH_TOKEN_FANS_CRM, user.refreshToken, 100);
      }

      if (user.jwt) {
        localStorage.setItem(ACCESS_TOKEN, user.jwt);
      }

      const prepearedUser = {...user, refreshToken: '', jwt: ''};
      localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(prepearedUser));

    }

    setCurrentUser(user);
  };

  const contextValue = useMemo(
    () => ({ currentUser, setCurrentUser: updateCurrentUserData }),
    [currentUser],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
