import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql, useMutation } from '@apollo/client';
import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react';
import { HidePassBtn, ShowPassBtn, SmallLogoIcon } from 'src/icons';
import { AuthContext } from '../context.component';
import { userDataFragment } from './fragments';

type InputsType = {
  email: string;
  password: string;
};

const LOGIN_MUTATION = gql`
  mutation login($input: LoginUserInput!) {
    signIn(input: $input) {
      me {
        ...UserData
      }
      jwt
      refreshToken
    }
  }
  ${userDataFragment}
`;

const schema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const Login = () => {
  const { register, handleSubmit } = useForm<InputsType>({
    resolver: yupResolver(schema),
    defaultValues: { email: '', password: '' },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [login, { data, loading, error }] = useMutation(LOGIN_MUTATION);
  const { setCurrentUser } = useContext(AuthContext);

  const send = async (
    formData: InputsType,
    e?: BaseSyntheticEvent,
  ): Promise<void> => {
    e?.preventDefault();

    if (!loading) {
      await login({
        variables: {
          input: { email: formData.email, password: formData.password },
        },
      });
    }
  };

  useEffect(() => {
    if (!error && !loading && data) {
      setCurrentUser({ ...data.signIn.me, jwt: data.signIn.jwt, refreshToken: data.signIn.refreshToken });
    }
  }, [data]);

  const togglePassBtn = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full h-screen flex items-center justify-center m-auto">
      <div className="max-w-md w-full m-auto mt-48 p-5">
        <SmallLogoIcon
          className="items-center justify-center m-auto mb-3"
          width={300}
          height={70}
        />
        <form className="pt-5" onSubmit={handleSubmit(send)}>
          <div className="relative z-0 w-full">
            <input
              placeholder="Email"
              className="fcrm-email py-2.5 px-4 mb-4"
              {...register('email')}
            />
          </div>
          <div className="relative z-0 w-full">
            <input
              type={`${showPassword ? 'text' : 'password'}`}
              placeholder="Password"
              className="fcrm-password py-2.5 px-4 my-2.5"
              {...register('password')}
            />
            <ShowPassBtn
              id="show-pass-btn"
              className={`${
                showPassword ? '' : 'hidden '
              }absolute top-2.5 right-3`}
              onClick={togglePassBtn}
            />
            <HidePassBtn
              id="hide-pass-btn"
              className={`${
                showPassword ? 'hidden ' : ''
              }absolute top-3 right-3`}
              onClick={togglePassBtn}
            />
          </div>
          {error && (
            <div className="fcrm-error-i">
              {error?.message || 'Internal error'}
            </div>
          )}
          <button
            type="submit"
            className="fcrm-submit bg-blue-500 py-2.5 my-1.5 rounded-full w-full hover:bg-blue-600 active:bg-blue-700">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};
