export enum ModelUsageStatusEnum {
  NO_MODELS = 'No models',
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT ACTIVE',
  LOST = 'LOST'
}

export enum ModelUsageStatusColorEnum {
  RED = '#d32f2f',
  YELLOW = '#cccc25',
  GREEN = '#2e7d32',
  GRAY = '#808080'
}