import { useCallback, useMemo } from 'react';
import {
  StringParam,
  NumberParam,
  useQueryParam,
  withDefault,
  useQueryParams,
  JsonParam,
  ArrayParam,
} from 'use-query-params';
import { FilterValue } from 'antd/es/table/interface';
import { SortData } from 'src/components/table';
import { QueryParamsKeysEnum } from 'src/types/query-params-keys.enum';

export const useSearchParamsHelper = () => {
  const [currentTab, setCurrentTab] = useQueryParam(
    QueryParamsKeysEnum.CURRENT_TAB,
    StringParam,
  );

  const [page, setPage] = useQueryParam(
    QueryParamsKeysEnum.PAGE,
    withDefault(NumberParam, 1),
  );

  const [limit, setLimit] = useQueryParam(
    QueryParamsKeysEnum.PAGINATION_LIMIT,
    withDefault(NumberParam, 10),
  );

  const [sortField, setSortField] = useQueryParam(
    QueryParamsKeysEnum.SORTING_FIELD,
    StringParam,
  );
  const [sortOrder, setSortOrder] = useQueryParam(
    QueryParamsKeysEnum.SORTING_ORDER,
    StringParam,
  );

  const [search, setSearch] = useQueryParam(
    QueryParamsKeysEnum.SEARCH_TEXT,
    StringParam,
  );

  const [sellsSearch, setSellsSearch] = useQueryParam(
    QueryParamsKeysEnum.SELLS_SEARCH,
    JsonParam,
  );

  const [filters, setFilters] = useQueryParams({
    status: ArrayParam,
    roles: ArrayParam,
  });

  const clearAllFilters = () => {
    setPage(1);
    setSellsSearch(undefined);
    setSearch(undefined);
    setFilters({ status: undefined, roles: undefined });
  };

  const setPaginationLimitParams = (value: number) => {
    setLimit(value);
    setPage(1);
  };

  const handleSetSearchParams = (searchString: string) => {
    setSearch(searchString || undefined);
    setPage(1);
  };

  const setSortParams = (sortObj?: SortData) => {
    if (sortObj?.field && sortObj?.order) {
      setSortOrder(sortObj.order);
      setSortField(sortObj.field.toString());
    } else {
      setSortOrder(undefined);
      setSortField(undefined);
    }
  };

  const setSellsSearchParams = useCallback(
    (key: string, value: string) => {
      setSellsSearch((prev: Record<string, string>) => {
        const params = { ...(prev || {}) };
        if (value) {
          params[key] = value;
        } else {
          delete params[key];
        }

        if (!Object.keys(params).length) return undefined;

        return params;
      });

      setPage(1);
    },
    [setSellsSearch, setPage],
  );

  const setTabChangeParams = (tab: string) => {
    setCurrentTab(tab);
    clearAllFilters();
  };

  const setFilterParams = (
    filtersData: Record<string, FilterValue | null>,
  ) => {
    setFilters(filtersData);
  };

  const canClear = useMemo(() => {
    return (
      !!search ||
      !!Object.values(filters)?.some(Boolean) ||
      (!!sellsSearch && !!Object.keys(sellsSearch).length)
    );
  }, [filters, sellsSearch, search]);

  return {
    setPageParams: setPage,
    setPaginationLimitParams,
    handleSetSearchParams,
    setSortParams,
    setTabChangeParams,
    setFilterParams,
    setSellsSearchParams,
    clearAllFilters,
    canClear,
    sellsSearch,
    searchText: search || '',
    currentPage: page,
    paginationLimit: limit,
    sortField,
    sortOrder,
    currentTab,
    filters: filters as Record<string, FilterValue | null>,
  };
};
