import { UserType } from 'src/components';
import { RolesEnum } from 'src/types';

export const userHasOneOfRole = (
  user: Partial<UserType>,
  roles: RolesEnum[],
): boolean => {
  if (!user?.role || !roles.length) {
    return false;
  }

  return !!roles.find((role) => role === user.role);
};
