import { gql, useMutation } from '@apollo/client';
import { createSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { RolesEnum } from 'src/types';
import { userHasOneOfRole } from 'src/helpers';
import { userDataFragment, UserType } from '../user';
import { companyDataFragmentUser } from './fragments';
import { AuthContext } from '../context.component';
import { UserCompanyType } from '../company';
import { QueryParamsKeysEnum } from 'src/types/query-params-keys.enum';
import { Button } from 'antd';
import { CloseWhiteIcon } from 'src/icons';

type RemoveUserFromCompanyMutation = {
  removeUserFromCompany: UserType;
};

const REMOVE_USER_FROM_COMPANY_MUTATION = gql`
  mutation removeUserFromCompany($input: RemoveUserFromCompanyInput!) {
    removeUserFromCompany(input: $input) {
      ...UserData
      company {
        ...CompanyData
      }
    }
  }
  ${userDataFragment}
  ${companyDataFragmentUser}
`;

export const RowCompanyName = ({ userId, companies }: UserCompanyType) => {
  const { currentUser } = useContext(AuthContext);

  const [removeUserFromCompany, { loading: removeUserFromCompanyLoading }] =
    useMutation<RemoveUserFromCompanyMutation>(
      REMOVE_USER_FROM_COMPANY_MUTATION,
    );

  const removeUser = async (targetCompanyId?: string): Promise<void> => {
    if (!removeUserFromCompanyLoading && targetCompanyId) {
      await removeUserFromCompany({
        variables: {
          input: {
            userId,
            companyId: targetCompanyId,
          },
        },
      });
    }
  };

  const handleNavigateWithParams = (searchText: string) => {
    const params = { [QueryParamsKeysEnum.SEARCH_TEXT]: searchText };
    const searchParams = createSearchParams(params).toString();
    const newTabUrl = `/companies?${searchParams}`;

    window.open(newTabUrl, '_blank');
  };

  return (
    <div>
      {!!companies.length &&
        companies.map((company) => (
          <div
            className="flex flex-row items-center justify-end pb-1.5 gap-[5px]"
            key={`company:${company.id}`}>
            <div
              className="hover:cursor-pointer hover:underline"
              onClick={(e) => {
                e.preventDefault();
                handleNavigateWithParams(company.name || '');
              }}>
              {company.name}
            </div>
            {(!!company.id &&
              currentUser &&
              !userHasOneOfRole(currentUser, [RolesEnum.CHATTER]) &&
              currentUser &&
              currentUser?.id !== userId && (
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();

                    removeUser(company.id);
                  }}
                  shape="circle"
                  danger
                  icon={<CloseWhiteIcon />}
                />
              )) || <div className="w-[35px] ml-2"></div>}
          </div>
        ))}
    </div>
  );
};
