import { FC } from 'react';
import { UserType } from '../user';

// @ts-ignore
import styles from './styles.module.css';
import { CompaignsIcon } from 'src/icons';
import { Tag } from 'antd';

interface IProps {
  row: UserType;
  labelColor: string;
}

export const RowCreators: FC<IProps> = ({ row, labelColor }) => {
  return (
    <div>
      {row?.chatModels?.length ? (
        <div className={styles.creatorDescr}>
          <p>{row?.chatModels?.length}</p>
          {row.chatModels?.find(
            (model) =>
              model.campaignsStats &&
              model.campaignsStats.totalSubscribers > 20,
          ) && <CompaignsIcon width={18} height={18} className="ml-4" />}
        </div>
      ) : (
        <Tag style={{ color: labelColor, borderColor: labelColor }}>
          not added
        </Tag>
      )}
    </div>
  );
};
